import { FC, useContext } from 'react';
import { List } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';

import { Market, MarketHelpers } from '@playq/services-shared';

import { randomId } from '/helpers';
import AppContext from '/component/Apps/App/AppContext';
import { BlockThemed } from '/shared/BlockThemed';
import { Target } from '/shared/FilePreview/helpers';

import AppMarket from './AppMarket';
import { styles } from './styles';

const AppMarketsComponent: FC<WithStyles<typeof styles>> = ({ classes }) => {
  const {
    form,
    appImage,
    marketImages,
    readonly,
    onUpdateValue,
    onFetchMarketImage,
    onSelectAppImage,
    marketValidationSchema,
  } = useContext(AppContext);
  const allMarkets = MarketHelpers.all;

  const handleChangeMarket = (market: Market) => (toggle: boolean, marketId?: string) => {
    const markets = form.markets;
    if (toggle && market in markets) {
      delete markets[market];
    } else {
      markets[market] = marketId || '';
    }
    onUpdateValue('markets', markets);
  };

  const handleSelectAppImage = (market?: Market) => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (market) {
      const url = marketImages[market];
      if (url) {
        const urlWithRequiredSearchParams = new URL(url);
        urlWithRequiredSearchParams.searchParams.append(Target.Hash, randomId());
        urlWithRequiredSearchParams.searchParams.append(Target.Type, 'image/jpeg');
        onSelectAppImage({ market, url: urlWithRequiredSearchParams.toString() });
      }
    }
  };

  const marketItems = allMarkets.map((market: Market) => {
    const imageUrl = marketImages[market];

    const imageSelected = appImage && 'market' in appImage && market === appImage.market;

    return (
      <AppMarket
        key={market}
        market={market}
        selected={market in form.markets}
        marketId={form.markets[market]}
        imageUrl={imageUrl}
        imageSelected={!!imageSelected}
        readonly={readonly}
        validationSchema={market in form.markets ? marketValidationSchema : null}
        changeMarket={handleChangeMarket(market)}
        fetchMarketImage={onFetchMarketImage}
        selectMarketImage={handleSelectAppImage}
      />
    );
  });

  return (
    <BlockThemed label='Markets' classes={{ block: classes.appMarkets }}>
      <List className={classes?.appMarketsList || ''}>{marketItems}</List>
    </BlockThemed>
  );
};

export const AppMarkets = withStyles(styles)(AppMarketsComponent);

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  appIcon: {
    justifySelf: 'center',
    alignSelf: 'flex-start',
    gridRow: '1 /span 2',
  },
  appContent: {
    display: 'grid',
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(4),
    gridTemplateColumns: '200px 1fr 1fr',
  },
}));

import { Component } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { withRouter } from 'react-router-dom';
import { UnregisterCallback } from 'history';

import { getErrorBoundaryQueryKey } from '/helpers';
import { IErrorBoundaryQueryData } from '/common/models';

import { IErrorBoundaryProps, IErrorBoundaryState } from './types';
import {
  BiCommentErrorIcon,
  ErrorBoundaryConainer,
  Error,
  ErrorBoundaryHeader,
  GoHomeLink,
  StyledArrowBack,
  errorBoundaryStyles,
  backButtonWrapperStyles,
} from './styles';

export class ErrorBoundaryWithRouter extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);

    this.state = { error: null };
  }

  componentDidCatch(error: Error) {
    const unregisterHistoryListener: UnregisterCallback = function registerHistoryListener(
      this: ErrorBoundaryWithRouter
    ) {
      return this.props.history.listen(() => {
        this.setState((prevState) => ({ ...prevState, error: null }));
        unregisterHistoryListener();
      });
    }.call(this);

    const queryKey = getErrorBoundaryQueryKey(this.props.history.location.pathname);
    const queryData = this.props.queryClient.getQueryData<IErrorBoundaryQueryData>(queryKey);
    const disableClose = queryData?.disableClose ?? true;

    this.setState((prevState) => ({ ...prevState, error, disableClose }));
  }

  updateQueryData(updatedData: Partial<IErrorBoundaryQueryData>) {
    const queryKey = getErrorBoundaryQueryKey(this.props.history.location.pathname);
    this.props.queryClient.setQueryData<IErrorBoundaryQueryData>(queryKey, (prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  }

  goBack() {
    this.updateQueryData({ disableClose: true, state: undefined, disableClear: false });
    this.props.history.goBack();
  }

  close() {
    this.updateQueryData({ disableClose: true });
    this.setState((prevState) => ({ ...prevState, disableClose: undefined, error: null }));
  }

  render() {
    return this.state.error ? (
      <ErrorBoundaryConainer>
        <Box sx={errorBoundaryStyles}>
          <Box sx={backButtonWrapperStyles}>
            <IconButton onClick={this.goBack.bind(this)} data-testid='go-back-button' size='large'>
              <StyledArrowBack />
            </IconButton>
            <Typography color='textSecondary'>Go to previous page</Typography>
          </Box>
          <IconButton disabled={this.state.disableClose} onClick={this.close.bind(this)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={errorBoundaryStyles}>
          <ErrorBoundaryHeader>Oops, there has been an error</ErrorBoundaryHeader>
        </Box>

        <Error>{this.state.error?.stack}</Error>

        <GoHomeLink to='/'>Go home</GoHomeLink>

        <BiCommentErrorIcon />
      </ErrorBoundaryConainer>
    ) : (
      this.props.children
    );
  }
}

export const ErrorBoundary = withRouter(ErrorBoundaryWithRouter);

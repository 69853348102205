import { FC } from 'react';
import { Dialog } from '@mui/material';

import { Apps, IAppSelection } from './Apps';

export interface IAppPickerProps {
  onClose: () => void;
  appSelection?: IAppSelection;
}

export const AppPicker: FC<IAppPickerProps> = ({ onClose, appSelection }) => (
  <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth='lg'>
    <Apps isModal={true} appSelection={appSelection} onClose={onClose} />
  </Dialog>
);

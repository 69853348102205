import { makeStyles } from '@mui/styles';

export const useAppIconStyles = makeStyles({
  image: {
    maxWidth: '100%',
    boxShadow: '2px 2px rgba(0,0,0,0.1)',
    marginRight: 5,
    borderRadius: 5,
  },
  icon: {
    fontSize: 200,
  },
});

import { array, object, string } from 'yup';
import { noop } from 'lodash';

import {
  UploadNewFile,
  UploadFileRevision,
  UploadFileEntryRequest,
  UploadEntry,
  FileRevision,
  FileResponse,
  FileID,
} from '@playq/octopus2-files';
import { Tag } from '@playq/octopus-common';
import { GenericFailure } from '@playq/services-shared';

import { getFileSha256, parseFileName, randomString } from '/helpers';
import { IFileUpload } from '/common/models';
import { snackbarService } from '/common/snackbarService';

import { lastRevision } from '../File/helpers';

import { IUseFileUploadDef } from './types';

const fieldRequiredSchema = (field: string) => string().trim().required(`${field} is required!`);

const schema = object().shape({
  name: fieldRequiredSchema('Name'),
  comment: fieldRequiredSchema('Comment').min(3, 'min 3 symbols'),
  tags: array(),
});

const isValid = (fu: IFileUpload): boolean => {
  return schema.isValidSync(fu);
};

export const encodeFileName = (str: string): string => encodeURIComponent(str).replace(/%20/g, ' ');

const isAllValid = (fus: IFileUpload[]): boolean => fus.every((fu: IFileUpload) => isValid(fu));

const makeEntryRequest = async (fu: IFileUpload, maxVersion?: number): Promise<UploadFileEntryRequest> => {
  const req = new UploadFileEntryRequest();
  const entry = await createEntryUpload(fu, maxVersion);

  req.tags = fu.tags;
  req.mime = fu.origin.type;
  req.entry = entry;

  return req;
};

const createFileUpload = (origin: File, tags: Tag[] = [], id?: string): IFileUpload => {
  // encode string to utf8 with space delimiter preserving
  const utf8Name = encodeFileName(origin.name);
  const [name, ext] = parseFileName(utf8Name);
  return {
    id: id || randomString(),
    origin,
    name,
    ext,
    tags,
    comment: 'No comment provided',
    foundInstances: [],
    selectedInstance: undefined,
  };
};

const createManyUploads = (fs: File[], tags: Tag[] = []): IFileUpload[] => {
  return fs.map((f: File) => createFileUpload(f, tags, undefined));
};

const reset = (fu: IFileUpload, originTags: Tag[] = []): IFileUpload => createFileUpload(fu.origin, originTags, fu.id);

const createEntryUpload = async (fu: IFileUpload, maxVersion?: number): Promise<UploadEntry> => {
  if (fu.fileID || fu.selectedInstance) {
    const rev = new UploadFileRevision();
    rev.name = fu.origin.name;
    rev.comment = fu.comment;
    rev.meta = '';
    rev.sha256 = await getFileSha256(fu.origin);
    rev.size = fu.origin.size;
    rev.fileID = fu.fileID ?? (fu.selectedInstance?.file?.id as FileID);
    rev.version = maxVersion ?? 0;
    if (fu.selectedInstance?.revisions[0]) {
      rev.version = fu.selectedInstance.revisions[0].version + 1;
    }

    return rev;
  }

  const newFile = new UploadNewFile();
  newFile.name = fu.name + (fu.ext ? `.${fu.ext}` : '');
  newFile.comment = fu.comment;
  newFile.meta = '';
  newFile.sha256 = await getFileSha256(fu.origin);
  newFile.size = fu.origin.size;

  return newFile;
};

const createEntryRequest = async (
  fu: IFileUpload,
  maxVersion?: number
): Promise<UploadFileEntryRequest | undefined> => {
  if (!isValid(fu)) {
    return;
  }

  return makeEntryRequest(fu, maxVersion);
};

const createManyEntryRequests = async (fus: IFileUpload[]): Promise<UploadFileEntryRequest[] | undefined> => {
  if (!isAllValid(fus)) {
    return;
  }

  return Promise.all(fus.map((fu: IFileUpload) => makeEntryRequest(fu)));
};

const getErrorMessage = (err: Error | GenericFailure): string =>
  (typeof err === 'string' ? err : err.message) || 'Unknown Error';

const updateRevisionUpdatedAt = (revision: FileRevision, updateRevision: IUseFileUploadDef['updateRevision']) => {
  if (!updateRevision) {
    return;
  }
  const updatedRevision = new FileRevision(revision.serialize());
  const updatedAt = new Date();
  const updatedAtAsString = updatedAt.toISOString();
  updatedRevision.updatedAt = updatedAt;
  updatedRevision.updatedAtAsString = updatedAtAsString;

  updateRevision(updatedRevision).then((data) =>
    data.bifold(noop, () => {
      snackbarService.error(
        'Could not update the revision of the file. Status Missed will not be changed. Refresh the page in few minutes to check if file was updated.'
      );
    })
  );
};

const getLastRevisionFromFileID = async (
  fileID: FileID,
  getFile: IUseFileUploadDef['getFile']
): Promise<FileRevision | undefined> => {
  const fileEither = await getFile?.(fileID);
  const fileResponse = fileEither?.value;
  if (fileResponse instanceof FileResponse) {
    return lastRevision(fileResponse);
  }
};

export const fileUploadHelpers = {
  createFileUpload,
  createManyUploads,
  createManyEntryRequests,
  createEntryRequest,
  createEntryUpload,
  reset,
  isValid,
  isAllValid,
  getErrorMessage,
  updateRevisionUpdatedAt,
  getLastRevisionFromFileID,
  schema,
};

import { styled } from '@mui/styles';
import { Button } from '@mui/material';

import { getOppositeColor } from '/helpers';
import { viewPort } from '/styles';

export const Toolbar = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: `calc(${viewPort.width}px - 2 * ${theme.spacing()})`,
  display: 'grid',
  gridTemplateColumns: ' 1fr auto auto',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const BulkUploadButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: getOppositeColor(theme.palette.success.main),
}));

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  FileResponse,
  FileRevisionID,
  CheckFileResponse,
  CheckFilesBatchResponse,
  FileState,
} from '@playq/octopus2-files';

import { timeUnitPrecisionMillisecondValues } from '/constants';
import { useCheckFile, useCheckFilesBatch } from '/api';
import { appToolkit } from '/store';

import { checkIfFileRevisionMissedOrProcessing, getCheckFilesRequest, lastRevision } from '../../File/helpers';
import { UseFileStatusesArgs } from '../types';

const uploadingTime = 5 * timeUnitPrecisionMillisecondValues.Minute;

const getTimeLeft = (fR: FileResponse) => uploadingTime - (Date.now() - (lastRevision(fR)?.updatedAt?.getTime() ?? 0));

export const useFilesStatuses = ({
  files,
  fileOrigin,
  isMissed,
  filterByMissed,
  checkFile = useCheckFile,
  checkFiles = useCheckFilesBatch,
  mutateDeletedFile,
}: UseFileStatusesArgs) => {
  const appID = useSelector(appToolkit.selectors.appID);

  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<number>(0);

  useEffect(
    function setShouldUpdateIfIsMissed() {
      if (isMissed === undefined) {
        return;
      }
      const hasNewFiles = [...(files ?? []), fileOrigin].some(
        (file) => !!file && Date.now() - lastRevision(file).updatedAt.getTime() < uploadingTime
      );
      setShouldUpdate(isMissed && hasNewFiles);
    },
    [fileOrigin, files, isMissed]
  );

  const filterCheckedFilesByMissedProcessing = useCallback(
    ({ request, state }: CheckFileResponse) => {
      const currentRevision = (files ?? [fileOrigin as FileResponse])
        ?.map?.((file) => lastRevision(file))
        ?.find((revision) => revision.id.id === (request as FileRevisionID).id);
      if (!currentRevision) {
        return false;
      }
      const { missed, processing } = checkIfFileRevisionMissedOrProcessing(currentRevision, state);
      return missed && processing;
    },
    [files, fileOrigin]
  );

  const updateFiles = useCallback(
    (response?: CheckFilesBatchResponse) => {
      if (!response || !files) {
        return;
      }
      const processingFiles = response.batch.filter(filterCheckedFilesByMissedProcessing);
      const newShouldUpdate = processingFiles.length > 0;
      setShouldUpdate(newShouldUpdate);
      // needed to trigger files checking every 10 sec until no processing files left
      if (newShouldUpdate) {
        const firstFile = files.find(
          (file) => lastRevision(file).id.id === (processingFiles[0].request as FileRevisionID).id
        );
        setTimeLeft(getTimeLeft(firstFile as FileResponse));
      }
    },
    [files, filterCheckedFilesByMissedProcessing]
  );

  const isFilesBatchCheckingEnabled = !!files && files.length > 0;

  const checkFilesBatch = useMemo(() => getCheckFilesRequest(files), [files]);
  const {
    checkedFiles,
    isFetching: isChecking,
    refetch: refetchBatchCheck,
  } = checkFiles({ appID, batch: checkFilesBatch }, { enabled: isFilesBatchCheckingEnabled, onSuccess: updateFiles });

  const updateFileOrigin = useCallback(
    (res: CheckFileResponse | undefined) => {
      if (!fileOrigin || !res) {
        return;
      }
      const isProcessing = filterCheckedFilesByMissedProcessing(res);
      setShouldUpdate(isProcessing);
      setTimeLeft(getTimeLeft(fileOrigin));
      if (!isProcessing) {
        setTimeLeft(0);
      }
    },
    [fileOrigin, filterCheckedFilesByMissedProcessing]
  );

  const checkFileRequest = useMemo(() => (fileOrigin ? lastRevision(fileOrigin).id : undefined), [fileOrigin]);

  const { refetch: refetchCheckFile } = checkFile(
    { appID, check: checkFileRequest },
    {
      onSuccess: updateFileOrigin,
    }
  );

  useEffect(
    function updateIn10SecIfShouldUpdate() {
      if (!shouldUpdate) {
        return;
      }
      const timer = setTimeout(() => {
        if (isFilesBatchCheckingEnabled) {
          refetchBatchCheck();
        }
        refetchCheckFile();
      }, 10 * 1000);

      return () => {
        clearTimeout(timer);
      };
    },
    [timeLeft, shouldUpdate, refetchBatchCheck, refetchCheckFile, isFilesBatchCheckingEnabled]
  );

  useEffect(
    function filterFilesAfterCheck() {
      const missedFilter = filterByMissed;
      if (!missedFilter || !files || !mutateDeletedFile) {
        return;
      }
      checkedFiles.forEach((file) => {
        const shouldBeInList =
          (file.state === FileState.Exists && !missedFilter.value) ||
          (file.state === FileState.NotFound && missedFilter.value);
        if (!shouldBeInList) {
          const tableEntity = files.find(
            (fileRevision) => fileRevision.file.id.id === (file.request as FileRevisionID).file
          );
          if (tableEntity) {
            mutateDeletedFile(tableEntity.file.id);
          }
        }
      });
    },
    [checkedFiles, files, filterByMissed, mutateDeletedFile]
  );

  return { timeLeft, checkedFiles, isChecking };
};

import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    appMarkets: {
      gridColumn: ' 2 /span 2',
    },
    appMarketsList: {
      display: 'grid',
      rowGap: theme.spacing(2),
    },
  });

import { ChangeEvent, FC, memo, useState } from 'react';
import {
  Box,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  InputLabel,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
  useEventCallback,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {
  Backup as BackupIcon,
  Delete as DeleteIcon,
  Title as TitleIcon,
  SettingsBackupRestore as RefreshIcon,
  OpenInNew as OpenInNewIcon,
  FileUpload as FileUploadIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

import { Tag } from '@playq/octopus-common';

import { FilePreview } from '/shared/FilePreview';
import { Tags } from '/shared/Tags';
import { clipboardWrite, formatSize } from '/helpers';
import { LinearProgressWithLabel } from '/shared/LinearProgressWithLabel';
import { snackbarService } from '/common/snackbarService';

import { IUploadItemProps } from './types';
import { useUploadItemStyles } from './styles';

export const UploadItem: FC<IUploadItemProps> = memo((props) => {
  const {
    entity,
    selected,
    disabled,
    error,
    processing,
    progress,
    fileID,
    fileRevisionID,
    checkExisting,

    generateName: propGenerateName,
    onRemove,
    onReset,
    onUpdate,
    onUpload,
    onToggleSelect,
    onRemoveTag,
  } = props;

  const classes = useUploadItemStyles();
  const [resetDisabled, setResetDisabled] = useState(true);

  const handleReset = () => {
    onReset(entity);
  };

  const handleUpload = useEventCallback(() => {
    onUpload(
      { ...entity, fileID, selectedInstance: undefined, foundInstances: [] },
      (fileID || fileRevisionID) !== undefined
    );
  });

  const handleUploadAsVersion = useEventCallback(() => {
    onUpload({ ...entity, fileID }, (fileID || fileRevisionID) !== undefined);
  });

  const handleRemove = () => {
    onRemove({ ...entity, fileID });
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setResetDisabled(false);
    onUpdate({
      ...entity,
      name,
    });
  };

  const handleCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
    const comment = e.target.value;
    setResetDisabled(false);
    onUpdate({
      ...entity,
      comment,
    });
  };

  const handleTagsChange = (tags: Tag[]) => {
    setResetDisabled(false);
    onUpdate({
      ...entity,
      tags,
    });
  };

  const handleGenerateName = () => {
    const name = generateName();
    clipboardWrite(name);
    snackbarService.info(`${name} copied to the clipboard`);
  };

  const generateName = () => {
    if (!propGenerateName) {
      return '';
    }

    return propGenerateName(entity.tags, undefined, fileID?.id);
  };

  const handleToggleSelect = () => {
    onToggleSelect(entity);
  };

  const handleInstanceChange = (e: SelectChangeEvent<number>) => {
    const selectedInstance = entity.foundInstances.find((instance) => instance.file.id.id === e.target.value);
    onUpdate({
      ...entity,
      selectedInstance,
      tags: selectedInstance?.tags ?? entity.tags,
    });
  };

  const disable = processing;
  return (
    <div className={classes.wrapper}>
      <Grid container={true} spacing={2} className={classes.itemContainer}>
        <Grid item={true} className={classes.justifyCenter}>
          {!fileRevisionID && (
            <Checkbox
              checked={selected}
              onChange={handleToggleSelect}
              inputProps={{ 'aria-label': 'primary checkbox' }}
              value='primary'
            />
          )}
        </Grid>

        <Grid item={true} className={classes.justifyCenter}>
          <div className={classes.image}>
            <FilePreview source={entity.origin} />
          </div>
        </Grid>

        <Grid item={true} xs={12} sm={true} container={true}>
          <Grid item={true} xs={12} container={true} spacing={3}>
            <Grid item={true} direction='column' container={true} sm={6} xs={12} lg={true}>
              <FormGroup>
                <FormControl className={classes.control}>
                  <Input
                    data-testid='input-name'
                    value={entity.name}
                    disabled={disabled}
                    onChange={handleNameChange}
                    endAdornment={entity.ext && <InputAdornment position='end'>{`.${entity.ext}`}</InputAdornment>}
                  />
                </FormControl>
                <FormControl className={classes.control}>
                  <Input
                    data-testid='input-comment'
                    value={entity.comment}
                    disabled={disabled}
                    onChange={handleCommentChange}
                  />
                </FormControl>

                {checkExisting && entity.selectedInstance && (
                  <FormControl variant='standard' className={classes.control}>
                    <InputLabel>Existing files:</InputLabel>
                    <Select
                      value={entity.selectedInstance.file.id.id}
                      renderValue={(val) => {
                        const file = entity.foundInstances.find((instance) => instance.file.id.id === val);
                        if (!file) {
                          return val;
                        }
                        return `${file.file.id.serialize()} v${file.revisions[0].version}`;
                      }}
                      onChange={handleInstanceChange}
                      size='small'
                      sx={{
                        flex: 1,
                      }}
                    >
                      {entity.foundInstances.map((instance) => (
                        <MenuItem key={instance.file.id.id} value={instance.file.id.id}>
                          {instance.file.id.serialize()} v{instance.revisions[0].version}
                          <Box
                            component={Link}
                            to={`/files/${instance.file.id.id}`}
                            target='_blank'
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            sx={{
                              marginLeft: 'auto',
                            }}
                          >
                            <OpenInNewIcon color='action' />
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {entity.origin?.size !== undefined && (
                  <Typography variant='body2' color='textSecondary'>
                    {formatSize(entity.origin.size)}
                  </Typography>
                )}
              </FormGroup>
            </Grid>
            <Grid item={true} direction='column' container={true} sm={6} xs={12}>
              <Grid item={true} className={classes.actionsContainer}>
                <div className={classes.info}>
                  {processing ? (
                    <LinearProgressWithLabel value={progress} className={classes.progress} />
                  ) : (
                    <div className={classes.flexContainer}>
                      <div className={classes.errorContainer}>
                        {error && (
                          <Tooltip title={error} placement='top'>
                            <Typography color='error' className={classes.errorLabel}>
                              Error: {error}
                            </Typography>
                          </Tooltip>
                        )}
                      </div>
                      <Stack direction='row'>
                        <Tooltip title='Reset file' placement='top'>
                          <IconButton
                            disabled={disable || resetDisabled}
                            className={classes.resetIcon}
                            onClick={handleReset}
                            component='div'
                            aria-label='reset'
                            size='large'
                          >
                            <RefreshIcon fontSize='small' />
                          </IconButton>
                        </Tooltip>
                        {propGenerateName && (
                          <Tooltip title={`Copy ${generateName()}`} placement='top'>
                            <IconButton
                              disabled={disable || !!error}
                              className={classes.autoGenerateIcon}
                              onClick={handleGenerateName}
                              component='div'
                              aria-label='generate'
                              size='large'
                            >
                              <TitleIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        )}
                        {entity.selectedInstance && checkExisting && (
                          <Tooltip
                            title={`Upload as new ${entity.selectedInstance.revisions[0].version + 1} version of ${entity.selectedInstance.file.id.serialize()}`}
                            placement='top'
                          >
                            <IconButton
                              disabled={disable}
                              hidden={!(fileID || fileRevisionID) && !!error}
                              onClick={handleUploadAsVersion}
                              aria-label='upload-version'
                              size='large'
                            >
                              <FileUploadIcon fontSize='small' color='warning' />
                            </IconButton>
                          </Tooltip>
                        )}

                        <Tooltip title='Upload new file' placement='top'>
                          <IconButton
                            disabled={disable}
                            hidden={!(fileID || fileRevisionID) && !!error}
                            onClick={handleUpload}
                            className={classes.uploadIcon}
                            aria-label='upload'
                            size='large'
                          >
                            <BackupIcon fontSize='small' />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Remove file' placement='top'>
                          <IconButton
                            disabled={disable}
                            onClick={handleRemove}
                            className={classes.removeIcon}
                            aria-label='remove'
                            size='large'
                          >
                            <DeleteIcon fontSize='small' />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </div>
                  )}
                </div>
              </Grid>

              <Grid item={true}>
                <Tags
                  tags={entity.tags}
                  onChange={handleTagsChange}
                  disabled={disabled}
                  className={classes.control}
                  canRemove={onRemoveTag}
                  label={null}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});

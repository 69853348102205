import { BiCommentError } from 'react-icons/bi';
import { styled } from '@mui/material';
import { red } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

import { ThemeProvider } from '/common/theme';
import { ThemeMode } from '/common/models';

const BOUNDARY_CONTAINER_PADDING_BOTTOM_REM = 3;
const BOUNDARY_CONTAINER_PADDING_SIDE_REM = 2;
const BOUNDARY_CONTAINER_PADDING_TOP_REM = 2;

export const ErrorBoundaryConainer = styled('section')`
  width: calc(100vw - ${BOUNDARY_CONTAINER_PADDING_SIDE_REM * 2}rem);
  height: calc(100vh - ${BOUNDARY_CONTAINER_PADDING_TOP_REM + BOUNDARY_CONTAINER_PADDING_BOTTOM_REM}rem);
  padding: ${BOUNDARY_CONTAINER_PADDING_TOP_REM}rem ${BOUNDARY_CONTAINER_PADDING_SIDE_REM}rem
    ${BOUNDARY_CONTAINER_PADDING_BOTTOM_REM}rem;
  position: relative;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const ErrorBoundaryHeader = styled('h1')`
  margin-left: 0.25rem;
  font-weight: 400;
`;

export const StyledArrowBack = styled(ArrowBack)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const ErrorBoundaryRow = styled('div')`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
`;

const inlineBoxStyles = {
  display: 'flex',
  alignItems: 'center',
};

export const errorBoundaryStyles = {
  ...inlineBoxStyles,
  justifyContent: 'space-between',
  pb: 2,
};

export const backButtonWrapperStyles = {
  ...inlineBoxStyles,
};

export const BiCommentErrorIcon = styled(BiCommentError)`
  color: ${({ theme }) => theme.palette.action.hover} !important;
  font-size: 30rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Error = styled(ErrorBoundaryRow)`
  color: ${({ theme }) => ThemeProvider.getAppropriateStyles(theme.palette.mode as ThemeMode, red[700], red[500])};
  line-height: 1.4rem;
  white-space: break-spaces;
  padding: 0 3rem;
  max-height: 70vh;
  overflow: auto;
`;

export const GoHomeLink = styled(Link)`
  position: absolute;
  padding-left: 1rem;
  bottom: ${BOUNDARY_CONTAINER_PADDING_BOTTOM_REM}rem;
  color: ${({ theme }) => theme.palette.text.secondary} !important;
  text-transform: capitalize;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

import { FC, MouseEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, CardActionArea, IconButton, Tooltip, Stack, Skeleton, useEventCallback } from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { Blank } from '@dyssent/blankjs';

import { OffsetLimit } from '@playq/services-shared';
import { App } from '@playq/octopus2-apps';

import { appBlankStyle } from '/styles';
import { useAppDispatch } from '/store';
import { selectApp as selectAppAction } from '/store/toolkits/app/actions';
import { LabelThemed, LabelThemedColor } from '/shared/LabelThemed';
import { QueryChangeType } from '/shared/Table';
import { SyntheticEventWithMetaKey } from '/common/models';

import { AppTitlesProps } from './types';
import { MarketIcon } from './MarketIcon';
import { PlatformIcon } from './PlatformIcon';
import {
  NotFoundTextWrapper,
  AppImage,
  Card,
  Cards,
  Content,
  LoadMoreWrapper,
  SelectedIcon,
  Title,
  Actions,
  FingerprintIdLabel,
} from './styles';

const skeletons = Array(6)
  .fill(null)
  .map((_val, index) => (
    <Card key={index} elevation={5}>
      <Content>
        <Stack spacing={1}>
          <Skeleton variant='rectangular' style={{ height: 18, marginTop: '5px' }} />
          <Skeleton
            variant='rectangular'
            style={{
              height: 18,
              width: '50%',
              marginTop: -2,
              borderRadius: 5,
            }}
          />
          <Skeleton
            variant='rectangular'
            style={{
              height: 32,
              borderRadius: 5,
            }}
          />
        </Stack>
      </Content>
      <Skeleton variant='rectangular' height={220} />
      <Content>
        <Skeleton variant='rectangular' height={26} width='75%' />
      </Content>
    </Card>
  ));

export const AppsTiles: FC<AppTitlesProps> = ({
  apps,
  selectedApp,
  query,
  onQueryChange,
  onDelete,
  canEditApp,
  total,
  loading,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const selectApp = useEventCallback((app: App) => () => dispatch(selectAppAction({ app })));

  const handleEditApp = (e: SyntheticEventWithMetaKey) => {
    preventDefault(e);
    const url = new URL((e.currentTarget as HTMLLinkElement).href);
    if (e.metaKey) {
      window.open(url, '_blank');
      return;
    }
    history.push(url.pathname);
  };

  const handleDelete = (app: App) => (e: MouseEvent) => {
    preventDefault(e);
    onDelete(app);
  };

  const handleLoadPrev = () => {
    onQueryChange({
      query: {
        ...query,
        iterator: new OffsetLimit({
          limit: query.iterator.limit,
          offset: query.iterator.offset - query.iterator.limit,
        }),
      },
      change: QueryChangeType.PrevPage,
    });
  };

  const handleLoadNext = () => {
    onQueryChange({
      query: {
        ...query,
        iterator: new OffsetLimit({
          limit: query.iterator.limit,
          offset: query.iterator.offset + query.iterator.limit,
        }),
      },
      change: QueryChangeType.NextPage,
    });
  };

  const preventDefault = (e: MouseEvent | SyntheticEventWithMetaKey) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const hasMarketsOrPlatforms = (app: App) => app.platforms.length > 0 || app.markets.length > 0;

  const canLoadPrev = apps.length > 0 && query.iterator.offset > 0;
  const canLoadNext = apps.length > 0 && query.iterator.offset + query.iterator.limit < total;

  return (
    <>
      {apps.length === 0 && !loading && (
        <NotFoundTextWrapper align='center' variant='h6'>
          There is no apps yet. Press Plus button to create a new one.
        </NotFoundTextWrapper>
      )}
      <Cards>
        {loading && skeletons}
        {apps.map((app) => (
          <Card key={app.id.serialize()} elevation={5}>
            {selectedApp !== undefined && app.id.id === selectedApp.id.id && <SelectedIcon />}
            <CardActionArea onClick={selectApp(app)}>
              <Content>
                <Title variant='subtitle1'>{app.name}</Title>
                <Stack spacing={1} alignItems='flex-start'>
                  <LabelThemed
                    withoutMargins={{ left: true }}
                    data-testid='app-id-label'
                    color={LabelThemedColor.Grey}
                    clickable={true}
                  >
                    AppID: {app.id.id}
                  </LabelThemed>
                  <Tooltip placement='top' title={`FingerprintID: ${app.fingerprintID.app}`}>
                    <FingerprintIdLabel withoutMargins={{ left: true }} color={LabelThemedColor.Grey} clickable={true}>
                      FingerprintID: {app.fingerprintID.app}
                    </FingerprintIdLabel>
                  </Tooltip>
                </Stack>
              </Content>
              <AppImage
                source={app.iconFile}
                spinnersize={20}
                Fallback={() => <Blank id={app.id.id} title={app.name} config={appBlankStyle} />}
              />
              {(canEditApp(app) || hasMarketsOrPlatforms(app)) && (
                <Content>
                  <Stack direction='row' spacing={1}>
                    {hasMarketsOrPlatforms(app) && (
                      <Stack direction='row' spacing={1} flexWrap='wrap'>
                        {app.platforms.map((platform) => (
                          <Tooltip key={platform} title={`${platform} Platform`} placement='top'>
                            <span>
                              <PlatformIcon platform={platform} />
                            </span>
                          </Tooltip>
                        ))}
                        {app.markets.map(({ market }) => (
                          <Tooltip key={market} title={`${market} Market`} placement='top'>
                            <span>
                              <MarketIcon market={market} />
                            </span>
                          </Tooltip>
                        ))}
                      </Stack>
                    )}
                    {canEditApp(app) && (
                      <Actions>
                        <Tooltip title='Edit app' placement='top'>
                          <IconButton
                            component={Link}
                            onMouseDown={preventDefault}
                            onClick={handleEditApp}
                            to={`/apps/manage/${app.routeName}`}
                            color='default'
                            size='small'
                          >
                            <EditIcon fontSize='small' />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Delete App' placement='top'>
                          <IconButton onMouseDown={preventDefault} onClick={handleDelete(app)} size='small'>
                            <DeleteIcon fontSize='small' />
                          </IconButton>
                        </Tooltip>
                      </Actions>
                    )}
                  </Stack>
                </Content>
              )}
            </CardActionArea>
          </Card>
        ))}
      </Cards>
      {apps.length !== 0 && (
        <LoadMoreWrapper>
          <Button
            onClick={handleLoadPrev}
            disabled={loading || !canLoadPrev}
            startIcon={<ExpandLessIcon />}
            color='primary'
            variant='outlined'
          >
            Prev
          </Button>
          <Button
            onClick={handleLoadNext}
            disabled={loading || !canLoadNext}
            endIcon={<ExpandMoreIcon />}
            color='primary'
            variant='outlined'
          >
            Next
          </Button>
        </LoadMoreWrapper>
      )}
    </>
  );
};

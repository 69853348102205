import { FC } from 'react';
import { Checkbox, ListItem, ListItemText } from '@mui/material';
import { WithStyles } from '@mui/styles';

import { Platform } from '@playq/octopus2-apps';

import { styles } from './styles';

interface IAppPlatformProps extends WithStyles<typeof styles> {
  platform: Platform;
  selected: boolean;
  readonly: boolean;
  togglePlatform: () => void;
}

const AppPlatform: FC<IAppPlatformProps> = (props) => {
  const { classes, platform, selected, readonly, togglePlatform } = props;

  return (
    <ListItem className={classes?.appPlatform || ''} button={true} disabled={readonly} onClick={togglePlatform}>
      <ListItemText primary={platform} />
      <Checkbox data-testid={`${platform}-checkbox`} checked={selected} />
    </ListItem>
  );
};

export default AppPlatform;

import { FC } from 'react';
import { TextField, Button } from '@mui/material';

import { clipboardWrite } from '/helpers';
import { snackbarService } from '/common/snackbarService';

import { StyledDialogActions } from '../styles';

import { IApiKeyCreatedCopyingPanelProps } from './types';
import { useStyles } from './styles';

export const ApiKeyCreatedCopyingPanel: FC<IApiKeyCreatedCopyingPanelProps> = (props) => {
  const { keyDataToCopy, keyCreationDialogTogglingHandler }: IApiKeyCreatedCopyingPanelProps = props;
  const classes = useStyles();
  const targetApiKey: string = keyDataToCopy?.key;

  const handleCopyClick = () => {
    clipboardWrite(targetApiKey);

    snackbarService.success(`Key ${keyDataToCopy?.name} successfully copied to clipboard!`);
  };

  return (
    <>
      <TextField
        className={classes.textField}
        value={targetApiKey}
        fullWidth={true}
        variant='outlined'
        margin='normal'
        name='name'
        label='Write it down and Keep it safe'
      />

      <StyledDialogActions>
        <Button
          variant='contained'
          color='secondary'
          type='button'
          size='large'
          onClick={keyCreationDialogTogglingHandler(false)}
        >
          Close
        </Button>

        <Button variant='contained' color='primary' type='button' size='large' onClick={handleCopyClick}>
          Copy
        </Button>
      </StyledDialogActions>
    </>
  );
};

import { oauth2ClientID } from '/constants';

export const getTokenFromResponse = (response: string): string | undefined => {
  const params: string[] = response.split('&');
  const token: string | undefined = params[0]?.split('access_token=')[1];
  return token;
};

export const oauthSignIn = (): void => {
  // Google's OAuth 2.0 endpoint for requesting an access token
  const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  const form = document.createElement('form');
  form.setAttribute('method', 'GET'); // Send as a GET request.
  form.setAttribute('action', oauth2Endpoint);

  // Parameters to pass to OAuth 2.0 endpoint.
  const params: { [key: string]: string } = {
    client_id: oauth2ClientID,
    redirect_uri: `${location.protocol}//${location.host}/auth/google`,
    response_type: 'token',
    scope: 'profile email',
    prompt: 'select_account',
    cookie_policy: 'single_host_origin',
    include_granted_scopes: 'true',
  };

  // Add form parameters as hidden input values.
  for (const p in params) {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', p);
    input.setAttribute('value', params[p]);
    form.appendChild(input);
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form);
  form.submit();
};

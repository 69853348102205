import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    appPlatforms: {
      marginTop: theme.spacing(4),
    },
    appPlatform: {
      display: 'flex',
      justifyContent: 'space-around',
      padding: `0 0 0 ${theme.spacing(1)}`,
      textTransform: 'none',
    },
  });

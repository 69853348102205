import { FC } from 'react';
import { FaAmazon, FaFacebook, FaGooglePlay } from 'react-icons/fa';
import { GrAppleAppStore } from 'react-icons/gr';

import { Market } from '@playq/services-shared';

import { SecondaryIcon } from './styles';

export const MarketIcon: FC<{ market: Market }> = ({ market }) => {
  let Icon;
  switch (market) {
    case Market.GooglePlay: {
      Icon = FaGooglePlay;
      break;
    }
    case Market.AmazonMarketplace: {
      Icon = FaAmazon;
      break;
    }
    case Market.Facebook: {
      Icon = FaFacebook;
      break;
    }
    case Market.AppleAppStore: {
      Icon = GrAppleAppStore;
      break;
    }
  }

  return <SecondaryIcon component={<Icon size={20} />} />;
};

import { KnownDevice, Device } from '@playq/octopus2-devices';
export const checkDeviceStatus = (device: Device) => {
  return device instanceof KnownDevice;
};
export const checkDeviceConnection = (device: Device) => {
  return device.connection !== undefined;
};
export const checkDeviceName = (device: Device) => {
  return device instanceof KnownDevice ? device.name : device.alias.installationId;
};
export const cutUnderline = (str: string) => {
  if (typeof str !== 'string') {
    return '';
  }
  return str.replace(/_/gi, ' ');
};

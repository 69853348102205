import { ChangeEvent, FC } from 'react';
import { WithStyles } from '@mui/styles';
import { Schema } from 'yup';

import { Environment } from '@playq/octopus-common';

import { TextFieldValidator } from '/shared/TextFieldValidator';

import { styles } from './styles';

interface IAppSaltProps extends WithStyles<typeof styles> {
  env: Environment;
  salt: string;
  validationSchema: Schema<string>;
  disabled: boolean;
  changeSalt: (event: ChangeEvent<HTMLInputElement>) => void;
}

const AppSalt: FC<IAppSaltProps> = (props) => {
  const { disabled, env, salt, validationSchema, changeSalt } = props;

  return (
    <TextFieldValidator
      disabled={disabled}
      required={true}
      label={env}
      value={salt}
      onChange={changeSalt}
      margin='normal'
      schema={validationSchema}
      data-testid={`salts-${env.toLocaleLowerCase()}`}
    />
  );
};

export default AppSalt;

import { FC, MouseEvent } from 'react';
import { Info as InfoIcon, Lock as LockIcon, LockOpen as LockOpenIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { WithStyles } from '@mui/styles';

import { IFingerprintSaltsValid } from './types';
import { styles } from './styles';

interface IAppSaltsLabelProps extends WithStyles<typeof styles> {
  openSalts: boolean;
  setOpenSalts: () => void;
  fingerprintSaltsValid: IFingerprintSaltsValid;
  readonly: boolean;
}

const AppSaltsLabel: FC<IAppSaltsLabelProps> = (props) => {
  const { classes, openSalts, setOpenSalts, fingerprintSaltsValid, readonly } = props;

  const invalidFingerprintSaltsEnv =
    (fingerprintSaltsValid.sandbox && 'sandbox') || (fingerprintSaltsValid.live && 'live');

  const handleOpenSalts = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (!readonly) {
      setOpenSalts();
    }
  };

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {invalidFingerprintSaltsEnv && (
        <Tooltip
          placement='top'
          title={`Salts mismatch Fingerprint clientSideSecret for ${invalidFingerprintSaltsEnv} environment.`}
        >
          <InfoIcon className={classes?.appSaltsInfoIcon || ''} color='error' />
        </Tooltip>
      )}
      <b>Salts</b>
      <IconButton
        color='inherit'
        className={classes?.appSaltsButton || ''}
        disabled={readonly}
        onClick={handleOpenSalts}
        size='large'
      >
        {openSalts ? <LockOpenIcon /> : <LockIcon />}
      </IconButton>
    </>
  );
};

export default AppSaltsLabel;

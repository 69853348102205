import { FileID, UploadSingleResponse, FileResponse, UploadBatchResponse } from '@playq/octopus2-files';
import { GenericFailure } from '@playq/services-shared';

import { services2, useFileDelete, useFilesUploadBatchScripted, useFileUploadRetry, useFileUploadScripted } from '/api';
import { IUseFileUploadDef } from '/component/Files/FilesUpload/types';
import { IFileUpload } from '/common/models';
import { fileUploadHelpers } from '/component/Files/FilesUpload/helpers';
import { ScriptHookType } from '/component/Files/File/types';

export const defaultFileUploadDef: IUseFileUploadDef = {
  uploadFileEntity: useFileUploadScripted as unknown as ScriptHookType<
    UploadSingleResponse,
    GenericFailure | Error,
    FileResponse
  >,
  uploadBatch: useFilesUploadBatchScripted as unknown as ScriptHookType<
    UploadBatchResponse,
    GenericFailure | Error,
    FileResponse[]
  >,
  uploadRetry: useFileUploadRetry,
  validate: async (fu: IFileUpload) => {
    let err;
    try {
      await fileUploadHelpers.schema.validate(fu);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,prefer-destructuring
      err = e.errors[0];
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return err;
  },
  isValid: (fu: IFileUpload) => fileUploadHelpers.isValid(fu),
  getFile: (id: FileID) => services2.filesService.getFile(id, []),
  updateRevision: services2.filesService.updateRevision.bind(services2.filesService),
  delete: useFileDelete,
};

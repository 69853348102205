import { FC } from 'react';

import { UserRole } from '@playq/octopus2-auth';

import { IRouteProps, IRoutingModuleBaseProps } from '/Router/models';
import { RoutesList } from '/Router/RoutesList';

import { ApiKeysManager } from './ApiKeysManager';

export const keysManagerPath = '/keys-manager';

const routesConfig = (basePath: string): IRouteProps[] => {
  return [
    {
      path: basePath,
      exact: true,
      guard: {
        role: UserRole.None,
      },
      component: ApiKeysManager,
    },
  ];
};

export const ApiKeysManagerRoutes: FC<IRoutingModuleBaseProps> = ({ basePath }) => {
  if (!basePath) {
    throw new Error(`ApiKeysManagerRoutes component. Can't build routes without basePath`);
  }

  const routes = routesConfig(basePath);

  return <RoutesList routes={routes} />;
};

import { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useEventCallback } from '@mui/material';

import { LoginResponse, LoginWithGoogle, SuccessfulLogin } from '@playq/octopus2-auth';
import { GenericFailure } from '@playq/services-shared';
import { Either } from '@playq/irt';

import { getTokenFromResponse } from '/component/Login/helpers';
import { authToolkit, useAppDispatch } from '/store';
import { login as loginAction } from '/store/toolkits/auth/actions';
import { companyId } from '/constants';
import { services2 } from '/api';
import { history } from '/Router/history';
import { snackbarService } from '/common/snackbarService';

import { IGoogleLoginRedirectHandlerDispatchProps, IGoogleLoginRedirectHandlerStateProps } from './types';

export const GoogleLoginRedirectHandler: FC<
  IGoogleLoginRedirectHandlerStateProps & IGoogleLoginRedirectHandlerDispatchProps
> = () => {
  const isLoggedIn = useSelector(authToolkit.selectors.loggedIn);
  const googleRedirectURL = useSelector(authToolkit.selectors.googleRedirectURL);

  const dispatch = useAppDispatch();

  const login = useEventCallback((payload: SuccessfulLogin) => dispatch(loginAction(payload)));

  const accessToken: string | undefined = useMemo(() => getTokenFromResponse(location.href), []);

  useEffect(() => {
    if (isLoggedIn && googleRedirectURL) {
      history.replace(googleRedirectURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    const invalidToken = accessToken === undefined;

    if (invalidToken && isLoggedIn) {
      history.replace('/');
    }

    if (invalidToken && !isLoggedIn) {
      history.replace('/login');
    }
  }, [accessToken, isLoggedIn]);

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    const loginMetadata: LoginWithGoogle = new LoginWithGoogle({
      companyId,
      accessToken,
    });

    services2.authService.login(loginMetadata).then((resData: Either<GenericFailure, LoginResponse>) => {
      resData.bifold(
        (loginResponse: LoginResponse) => {
          if (loginResponse instanceof SuccessfulLogin) {
            login(loginResponse);
          }
        },
        (err: GenericFailure) => {
          snackbarService.error(`Login failed, ${err.message}`);
          history.replace('/login');
        }
      );
    });
  }, [accessToken, login]);

  return (
    <div className='ball-scale-multiple'>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

import { createStyles } from '@mui/styles';

export const styles = () =>
  createStyles({
    appLabel: {
      cursor: 'pointer',
    },
    barContent: {
      maxWidth: '350px',
    },
  });

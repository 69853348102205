import { FC, ChangeEvent, useState, useMemo, useCallback } from 'react';
import { Button, FormControl, FormGroup, FormHelperText, SelectChangeEvent, TextField } from '@mui/material';
import _ from 'lodash';

import { AccessLevel } from '@playq/octopus2-auth';

import { SelectFormControl } from '/shared/SelectFormControl';
import { useStateChange } from '/common/useStateChange';

import { StyledDialogActions } from '../styles';

import { IApiKeyFormProps, ICurrentAccessLevelOption, ISelectAccessLevelOptions } from './types';
import { useStyles } from './styles';

const selectAccessLevelOptions: ISelectAccessLevelOptions = {
  None: AccessLevel.None,
  Read: AccessLevel.Read,
  Write: AccessLevel.Write,
  'Write Live': AccessLevel.WriteLive,
};

export const ApiKeyForm: FC<IApiKeyFormProps> = (props) => {
  const {
    isKeyCreationBeingProcessed,
    tableQueryToPerformAfterKeyCreation,
    defaultKeyAccessLevel,
    keyCreationFormSubmissionHandler,
  }: IApiKeyFormProps = props;

  const [keyName, setKeyName] = useState<string>('');

  const getInitialKeyAccessLevel = useCallback(() => {
    const targetLabelIndex: number = Object.values(selectAccessLevelOptions).findIndex(
      (value: AccessLevel) => value === defaultKeyAccessLevel
    );

    return {
      label: Object.keys(selectAccessLevelOptions)[targetLabelIndex] as keyof ISelectAccessLevelOptions,
      value: Object.values(selectAccessLevelOptions)[targetLabelIndex] as AccessLevel,
    };
  }, [defaultKeyAccessLevel]);

  const [keyAccessLevel, setKeyAccessLevel] = useState<ICurrentAccessLevelOption>(() => getInitialKeyAccessLevel());
  const [formError, setFormError] = useState<string>('');
  const classes = useStyles();

  const noChanges = useMemo(
    () => !keyName && _.isEqual(keyAccessLevel, getInitialKeyAccessLevel()),
    [keyName, keyAccessLevel, getInitialKeyAccessLevel]
  );

  useStateChange(noChanges);

  const areThereAnyFormErrors = useMemo((): boolean => Boolean(formError), [formError]);

  const handleAccessLevelChange = (event: SelectChangeEvent<unknown>) => {
    const targetAccessLevelLabel = event.target.value as keyof ISelectAccessLevelOptions;
    const targetAccessLevelValue: AccessLevel = selectAccessLevelOptions[targetAccessLevelLabel];

    setKeyAccessLevel({
      label: targetAccessLevelLabel,
      value: targetAccessLevelValue,
    });
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newKeyName: string = event.target.value;

    setKeyName(newKeyName);

    if (newKeyName.length < 3) {
      setFormError('The field must be a string with a minimum length of 3 characters.');
    } else if (areThereAnyFormErrors) {
      setFormError('');
    }
  };

  const handleFormSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    keyCreationFormSubmissionHandler(keyName, keyAccessLevel.value, tableQueryToPerformAfterKeyCreation);
  };

  const isSaveDisabled = useMemo(
    () => noChanges || areThereAnyFormErrors || isKeyCreationBeingProcessed,
    [noChanges, areThereAnyFormErrors, isKeyCreationBeingProcessed]
  );

  return (
    <form onSubmit={handleFormSubmit}>
      <FormGroup>
        <FormControl error={areThereAnyFormErrors}>
          <TextField
            value={keyName}
            error={areThereAnyFormErrors}
            onChange={handleNameChange}
            disabled={false}
            autoFocus={true}
            fullWidth={true}
            required={true}
            variant='outlined'
            margin='normal'
            name='name'
            label='Name'
          />

          {areThereAnyFormErrors && <FormHelperText>{formError}</FormHelperText>}
        </FormControl>
      </FormGroup>

      <StyledDialogActions>
        <SelectFormControl
          className={classes.selectAccessLevelControl}
          label='Access'
          selectedOption={keyAccessLevel.label}
          options={Object.keys(selectAccessLevelOptions)}
          onOptionSelect={handleAccessLevelChange}
        />

        <Button disabled={isSaveDisabled} variant='contained' color='primary' type='submit'>
          Save
        </Button>
      </StyledDialogActions>
    </form>
  );
};

import { SyntheticEvent, FC, useState, useCallback, useEffect, useMemo } from 'react';

import { AccessLevel, UserApiKeySerialized, UserApiKeyDataSerialized } from '@playq/octopus2-auth';
import { TextFilter, TimeFilter } from '@playq/octopus-common';

import { useTableQueryChange } from '/hooks';
import { services2 } from '/api';
import { throwGenericFailure, formatDate } from '/helpers';
import { snackbarService } from '/common/snackbarService';
import { DeleteIconStyled as DeleteIcon } from '/shared/Tree/plugins/styles';
import { confirmDialog } from '/common/ConfirmDialog';
import { IGenericTableEntity } from '/common/models';
import { ITableQuery } from '/shared/Table';

import { ApiKeysManagerContent } from './ApiKeysManagerContent';
import {
  ApiKeyDataToCopy,
  IApiKeyCreationMetadata,
  IIndependentApiKeysTableContentMetadata,
  ApiKeysQueryFields,
  ExistingApiKeyIdBeingProcessed,
  IKeyCreationProcessingConfig,
} from './types';

const defaultAccessLevelValue = AccessLevel.Read;

export const ApiKeysManager: FC = () => {
  const [apiKeysData, setApiKeysData] = useState<UserApiKeyDataSerialized[]>([]);
  const [tableEntries, setTableEntries] = useState<UserApiKeyDataSerialized[]>([]);
  const [existingKeyIdBeingProcessed, setExistingKeyIdBeingProcessed] = useState<ExistingApiKeyIdBeingProcessed>(null);
  const [keyDataToCopy, setKeyDataToCopy] = useState<ApiKeyDataToCopy>(null);
  const [isKeyCreationDialogOpened, setIsKeyCreationDialogOpened] = useState<boolean>(false);
  const [keyCreationMetadata, setKeyCreationMetadata] = useState<IApiKeyCreationMetadata>({
    isKeyCreationBeingProcessed: false,
    newKeyName: '',
    newKeyAccessLevel: defaultAccessLevelValue,
    tableQueryToPerformAfterKeyCreation: null,
  });
  const [isTableProcessing, setIsTableProcessing] = useState<boolean>(false);

  const independentTableContentMetadata: IIndependentApiKeysTableContentMetadata = {
    columns: [
      {
        label: 'ID',
        render: (entry: UserApiKeyDataSerialized) => entry.id,
        sortField: ApiKeysQueryFields.ID,
        filterField: ApiKeysQueryFields.ID,
        filterType: TextFilter.ClassName,
      },
      {
        label: 'Name',
        render: (entry: UserApiKeyDataSerialized) => entry.name,
        sortField: ApiKeysQueryFields.Name,
        filterField: ApiKeysQueryFields.Name,
        filterType: TextFilter.ClassName,
      },
      {
        label: 'Created At',
        render: (entry: UserApiKeyDataSerialized) => formatDate(entry.created),
        sortField: ApiKeysQueryFields.CreatedAt,
        filterField: ApiKeysQueryFields.CreatedAt,
        filterType: TimeFilter.ClassName,
      },
      {
        label: 'Last Used At',
        render: (entry: UserApiKeyDataSerialized) => {
          const lastUsed: string | undefined = entry.lastUsed;

          return !lastUsed ? '' : formatDate(lastUsed);
        },
        sortField: ApiKeysQueryFields.LastUsedAt,
        filterField: ApiKeysQueryFields.LastUsedAt,
        filterType: TimeFilter.ClassName,
      },
    ],
    rowKeyResolver: (entry: UserApiKeyDataSerialized) => entry.id,
  };

  const handleTableEntriesSetting = (data: IGenericTableEntity[]) => {
    setTableEntries(data as unknown as UserApiKeyDataSerialized[]);
  };

  const [, setTableQuery] = useTableQueryChange({
    tableData: apiKeysData as unknown as IGenericTableEntity[],
    tableEntriesSettingHandler: handleTableEntriesSetting,
    isTableProcessingSettingHandler: setIsTableProcessing,
  });

  const dependentTableContentMetadata = useMemo(
    () => ({
      data: tableEntries,
      rowActions: [
        {
          label: 'Delete',
          icon: DeleteIcon,
          tooltip: (entry: UserApiKeyDataSerialized) => `Delete ${entry.name} `,
          pending: (entry: UserApiKeyDataSerialized) => entry.id === existingKeyIdBeingProcessed,
          onClick: (_event: SyntheticEvent, entry: UserApiKeyDataSerialized) => {
            const entryName: string = entry.name;

            confirmDialog({
              title: `Delete ${entryName}?`,
              text: `Are you sure you want to delete '${entryName}'?`,
              closeButton: { label: 'NO' },
              successButton: { label: 'YES' },
              onSuccess: () => deleteApiKey(entry.id),
            });
          },
        },
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [existingKeyIdBeingProcessed, tableEntries]
  );

  const handleApiKeyDeletion = useCallback((keyId: number) => {
    setApiKeysData((prevApiKeysData) => {
      const currentApiKeysData: UserApiKeyDataSerialized[] = prevApiKeysData.slice();
      const deletedApiKeyDataIndex: number = prevApiKeysData.findIndex(
        (entry: UserApiKeyDataSerialized): boolean => entry.id === keyId
      );

      currentApiKeysData.splice(deletedApiKeyDataIndex, 1);

      return currentApiKeysData;
    });

    setTableEntries((prevTableEntries) => {
      const currentTableEntries: UserApiKeyDataSerialized[] = prevTableEntries.slice();
      const deletedTableEntryIndex: number = prevTableEntries.findIndex(
        (entry: UserApiKeyDataSerialized): boolean => entry.id === keyId
      );

      currentTableEntries.splice(deletedTableEntryIndex, 1);

      return currentTableEntries;
    });

    setExistingKeyIdBeingProcessed(null);
  }, []);

  const deleteApiKey = (keyId: number) => {
    setExistingKeyIdBeingProcessed(keyId);

    services2.userProfileService
      .deleteApiKey(keyId)
      .then((data) => {
        data.bifold(
          () => {
            handleApiKeyDeletion(keyId);
          },
          (err) => throwGenericFailure(err)
        );
      })
      .catch((err: Error) => {
        snackbarService.error(`delete api key -${err.message}`);
      });
  };

  const handleApiKeyCreationDialogToggling = useCallback(
    (shouldBeOpened: boolean) => () => {
      setIsKeyCreationDialogOpened(shouldBeOpened);
    },
    []
  );

  const toggleKeyCreationProcessing = useCallback((config: IKeyCreationProcessingConfig) => {
    const {
      isCreationBeingProcessed,
      keyName = '',
      keyAccessLevel = defaultAccessLevelValue,
      tableQuery,
    }: IKeyCreationProcessingConfig = config;

    setKeyCreationMetadata({
      isKeyCreationBeingProcessed: isCreationBeingProcessed,
      newKeyName: keyName,
      newKeyAccessLevel: keyAccessLevel,
      tableQueryToPerformAfterKeyCreation: tableQuery || null,
    });
  }, []);

  const createApiKey = useCallback(
    (keyName: string, accessLevel: AccessLevel) => {
      services2.userProfileService
        .createApiKey(keyName, accessLevel)
        .then((data) => {
          data.bifold(
            (res) => {
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              setKeyDataToCopy(res?.serialize() || null);
            },
            (err) => throwGenericFailure(err)
          );
        })
        .catch((err: Error) => {
          snackbarService.error(`create api key -${err.message}`);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableEntries]
  );

  const handleApiKeyCreationFormSubmission = useCallback(
    (keyName: string, keyAccessLevel: AccessLevel, tableQuery: ITableQuery<string>) => {
      toggleKeyCreationProcessing({
        isCreationBeingProcessed: true,
        keyName,
        keyAccessLevel,
        tableQuery,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleApiKeyObtainment = useCallback((keysSerialized: UserApiKeyDataSerialized[]) => {
    if (!Array.isArray(keysSerialized)) {
      return;
    }

    resetApiKeysTableData(keysSerialized);
  }, []);

  const obtainApiKeyTableEntries = useCallback(() => {
    setIsTableProcessing(true);

    services2.userProfileService
      .getApiKeys()
      .then((data) => {
        data.bifold(
          (res) => {
            handleApiKeyObtainment(res?.serialize().keys);
            setIsTableProcessing(false);
          },
          (err) => throwGenericFailure(err)
        );
      })
      .catch((err: Error) => {
        snackbarService.error(`retrieve user api keys -${err.message}`);
      });
  }, [handleApiKeyObtainment]);

  const handleApiKeyCreationDialogFadingOut = useCallback(() => {
    setKeyDataToCopy(null);
    setExistingKeyIdBeingProcessed(null);
  }, []);

  const resetApiKeysTableData = (
    newKeysData: UserApiKeyDataSerialized[],
    newTableEntries?: UserApiKeyDataSerialized[]
  ) => {
    setApiKeysData(newKeysData);
    setTableEntries(newTableEntries || newKeysData.slice());
  };

  const { isKeyCreationBeingProcessed, newKeyName, newKeyAccessLevel, tableQueryToPerformAfterKeyCreation } =
    keyCreationMetadata;

  useEffect(() => {
    obtainApiKeyTableEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isKeyCreationBeingProcessed) {
      createApiKey(newKeyName, newKeyAccessLevel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isKeyCreationBeingProcessed]);

  useEffect(() => {
    if (keyDataToCopy) {
      const { key, ...apiKeyData }: UserApiKeySerialized = keyDataToCopy;

      setApiKeysData(apiKeysData.concat(apiKeyData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyDataToCopy]);

  useEffect(() => {
    if (isKeyCreationBeingProcessed && tableQueryToPerformAfterKeyCreation) {
      setTableQuery(tableQueryToPerformAfterKeyCreation);
      toggleKeyCreationProcessing({ isCreationBeingProcessed: false });

      snackbarService.success(`Key ${keyDataToCopy?.name || ''} successfully created!`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKeysData]);

  return (
    <ApiKeysManagerContent
      keysData={apiKeysData}
      isKeyCreationDialogOpened={isKeyCreationDialogOpened}
      isKeyCreationBeingProcessed={isKeyCreationBeingProcessed}
      isTableProcessing={isTableProcessing}
      tableContentMetadata={{
        ...dependentTableContentMetadata,
        ...independentTableContentMetadata,
      }}
      keyDataToCopy={keyDataToCopy}
      defaultKeyAccessLevel={defaultAccessLevelValue}
      keyCreationDialogTogglingHandler={handleApiKeyCreationDialogToggling}
      keyCreationFormSubmissionHandler={handleApiKeyCreationFormSubmission}
      keyCreationDialogFadingOutHandler={handleApiKeyCreationDialogFadingOut}
      tableEntriesSettingHandler={handleTableEntriesSetting}
      isTableProcessingSettingHandler={setIsTableProcessing}
    />
  );
};

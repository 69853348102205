import { CouponsSort, CouponsSortField } from '@playq/octopus2-economy';

import { IQuery } from '/common/models';
import { ICollectionTableQueryChange } from '/shared/Table';

export enum CouponType {
  mass = 'mass',
  targeted = 'targeted',
}

export type CouponsQuery = IQuery<CouponsSortField, CouponsSort>;

export type CouponsQueryChange = ICollectionTableQueryChange<CouponsQuery>;

import { FC, ReactNode, useMemo } from 'react';
import _ from 'lodash';

import { FileResponse, FileRevision } from '@playq/octopus2-files';

import { SearchListPopover } from '/shared/SearchList';
import { ISelectedFile } from '/component/Files/FilePicker';

export interface IVersionPickerProps {
  file: FileResponse;
  selectedRevision?: FileRevision;
  isLink?: boolean;
  emptyPlaceholder?: string;
  renderLabel?: (revision: FileRevision) => ReactNode;
  onSelect?: (selectedFile: ISelectedFile) => void;
}

const renderLabelComment = (rev: FileRevision) => rev.comment;
const getVersionItem = (rev: FileRevision) => (rev.comment ? `${rev.version} - ${rev.comment}` : `${rev.version}`);

export const VersionsPicker: FC<IVersionPickerProps> = (props) => {
  const { file, selectedRevision, isLink = true, emptyPlaceholder, renderLabel = renderLabelComment, onSelect } = props;

  const lastRevision = useMemo(() => {
    return _.maxBy(file.revisions, (r: FileRevision) => r.version) as FileRevision;
  }, [file]);

  const versionList = useMemo(() => {
    return file.revisions
      .sort((a: FileRevision, b: FileRevision) => b.version - a.version)
      .map((rev: FileRevision) => getVersionItem(rev));
  }, [file.revisions]);

  const handleItemSelect = (version: string) => {
    const head = _.head(version.split('-'));
    const v = head?.trim();
    if (onSelect) {
      const revision = file.revisions.find((r) => r.version.toString() === v);

      if (revision) {
        onSelect({ fileRevision: revision, fileResponse: file });
      }
    }
  };

  const selected = selectedRevision || lastRevision;
  const label = selectedRevision ? renderLabel(selectedRevision) : (emptyPlaceholder ?? renderLabel(selected));

  return (
    <SearchListPopover
      label={label}
      isLink={isLink}
      itemList={versionList}
      placeholder='Find version'
      onItemSelect={handleItemSelect}
    />
  );
};

import { useCallback, useEffect } from 'react';

const safeDocument = document ?? {};

export const useEnableBodyScroll = (shouldDisable = true) => {
  const body = safeDocument.body;

  const disableBodyScroll = useCallback(() => {
    if (body === undefined || !shouldDisable) {
      return;
    }
    body.style.position = 'relative';
    body.style.overflow = 'hidden';
  }, [body, shouldDisable]);

  const enableBodyScroll = useCallback(() => {
    if (body === undefined || !shouldDisable) {
      return;
    }
    body.style.position = '';
    body.style.overflow = '';
  }, [body, shouldDisable]);

  useEffect(() => {
    return () => {
      enableBodyScroll();
    };
  }, [enableBodyScroll]);

  return { disableBodyScroll, enableBodyScroll };
};

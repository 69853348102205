import { FileResponse, FileResponseSerialized } from '@playq/octopus2-files';

import { IFilesRouteProps } from '/common/models';

export const getStateFromRoute = (
  location: IFilesRouteProps['location']
): { files: File[]; selectedFiles: FileResponse[]; fileRevisionID?: string; retryFileID?: string } => {
  const expectedFiles = location?.state;
  if (expectedFiles && expectedFiles instanceof Object) {
    const isAllFiles = expectedFiles.files.every((f: unknown) => f instanceof File);
    if (isAllFiles) {
      return {
        files: expectedFiles.files,
        selectedFiles: expectedFiles.selectedFiles.map((f: FileResponseSerialized) => new FileResponse(f)),
        fileRevisionID: expectedFiles.fileRevisionID,
        retryFileID: expectedFiles.retryFileID,
      };
    }
  }

  return { files: [], selectedFiles: [] };
};

import { FC, memo } from 'react';
import { Blank } from '@dyssent/blankjs';
import { styled } from '@mui/material';

import { App } from '@playq/octopus2-apps';

import { appBlankStyle } from '/styles';
import { SourcePreview } from '/shared/FilePreview';

interface IAppIconProps {
  app: App;
  lessShadow?: boolean;
}

const commonStyles = `
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border-style: solid;
  border-color: #cecece;
  border-width: 1px;
  box-shadow:
    0 0 0 1px rgba(16, 22, 26, 0.1),
    0 2px 4px rgba(16, 22, 26, 0.2),
    0 8px 24px rgba(16, 22, 26, 0.2);
  box-sizing: border-box;

  img {
    object-fit: initial;
  }
`;

const StyledBlank = styled(Blank)`
  ${commonStyles}
`;

const StyledPreview = styled(SourcePreview)`
  ${commonStyles}
`;

const StyledPreviewNoShadow = styled(SourcePreview)`
  ${commonStyles};
  box-shadow: none;
`;

export const AppIcon: FC<IAppIconProps> = memo(({ app, lessShadow }) => {
  const PreviewFallback = () => <StyledBlank id={app.id.id} title={app.name} config={appBlankStyle} />;

  return lessShadow ? (
    <StyledPreviewNoShadow source={app.iconFile} spinnersize={20} Fallback={PreviewFallback} />
  ) : (
    <StyledPreview source={app.iconFile} spinnersize={20} Fallback={PreviewFallback} />
  );
});

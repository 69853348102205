import { FC, ChangeEvent, useEffect, useRef } from 'react';

import { Market as MarketsEnum } from '@playq/services-shared';

import { useDebounce } from '/hooks';
import { Market, IMarketProps } from '/shared/Market';

interface IAppMarketProps extends Omit<IMarketProps, 'onIdChange'> {
  fetchMarketImage: (market: MarketsEnum, id: string) => void;
}

const AppMarket: FC<IAppMarketProps> = (props) => {
  const {
    market,
    selected,
    marketId,
    imageUrl,
    imageSelected,
    readonly,
    validationSchema,
    changeMarket,
    fetchMarketImage,
    selectMarketImage,
  } = props;

  const debouncedId = useDebounce(marketId, 1000);
  const isInitialValue = useRef(true);

  useEffect(() => {
    if (isInitialValue.current) {
      return;
    }

    fetchMarketImage(market, debouncedId);
  }, [debouncedId, fetchMarketImage, market]);

  const handleIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isInitialValue.current) {
      isInitialValue.current = false;
    }

    changeMarket(false, event.target.value);
  };

  return (
    <Market
      market={market}
      marketId={marketId}
      imageUrl={imageUrl}
      imageSelected={imageSelected}
      selected={selected}
      validationSchema={validationSchema}
      readonly={readonly}
      changeMarket={changeMarket}
      selectMarketImage={selectMarketImage}
      onIdChange={handleIdChange}
    />
  );
};

export default AppMarket;

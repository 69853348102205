import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';

import { App } from '@playq/octopus2-apps';
import {
  FileID,
  FileFeature,
  FileResponse,
  FileRevisionID,
  FilesFilterField,
  FilesSort,
  FilesSortField,
} from '@playq/octopus2-files';
import {
  Tag,
  TagsFilter,
  TagCollapseFilter,
  Filter,
  TextFilter,
  BooleanFilter,
  NumberFilter,
  FilterExpression,
} from '@playq/octopus-common';

import { getTagLabel, isSystemTag, QueryHelpers } from '/helpers';
import { usePersistedQuery } from '/hooks';
import { fileGetKeys } from '/api';
import {
  createFileMutationKey,
  filesQueryKeys,
  removeFileMutationKey,
  updateFileMutationKey,
  updateFileRevisionMutationKey,
  uploadFileMutationKey,
  uploadRetryFileMutationKey,
  uploadBatchFilesMutationKey,
  useFileDelete,
  useFileDeleteBatch,
  useFilesQuery,
} from '/api/hooks/filesService';
import { appToolkit, authToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { QueryFilter } from '/common/models';
import { confirmDialog, ConfirmDialogType } from '/common/ConfirmDialog';

import { useFilesStatuses } from './helpers';
import { FilesListTable } from './FilesListTable';
import { IFilesQuery, IFilesListProps } from './types';

export type GetInitialQueryArgs = {
  initQuery: IFilesQuery;
  app: App | undefined;
  globalSearch: string | undefined;
  idSearch: string | undefined;
  selectedRevID: FileRevisionID | undefined;
  showOnlyCompletedFiles?: boolean;
};

const getInitialQuery = ({
  initQuery,
  app,
  globalSearch,
  idSearch,
  selectedRevID,
  showOnlyCompletedFiles,
}: GetInitialQueryArgs) => {
  const query = initQuery;
  const tagsFilter = (initQuery.filterBy[FilesFilterField.Tag] as TagsFilter) ?? new TagsFilter();

  if (app != null && !tagsFilter.includes.find((t: Tag) => t.key === '$app')) {
    const appTag = new Tag({ key: 'app', value: `${app.id.id}` });
    tagsFilter.includes = tagsFilter.includes.filter((t: Tag) => t.key !== 'app');
    tagsFilter.includes.push(appTag);
  }

  let anyFilter;
  if (globalSearch) {
    anyFilter = new TextFilter({ text: globalSearch });
  } else if (selectedRevID) {
    anyFilter = new TextFilter({ text: selectedRevID.file.toString() });
  }

  if (anyFilter) {
    query.filterBy[FilesFilterField.Any] = anyFilter;
  }

  if (idSearch) {
    query.filterBy[FilesFilterField.Id] = new NumberFilter({
      left: parseInt(idSearch),
      expression: FilterExpression.Equal,
      right: undefined,
    });
  }

  if (tagsFilter.includes.length || tagsFilter.excludes.length) {
    query.filterBy[FilesFilterField.Tag] = tagsFilter;
  }

  if (showOnlyCompletedFiles) {
    const missedFilter = new BooleanFilter({ value: false });
    query.filterBy[FilesFilterField.IsMissed] = missedFilter;
  }

  return query;
};

type FilesQueryStateProps = IFilesListProps & {
  queryKey?: string;
  app?: App;
};

const useFilesQueryState = ({
  globalSearch,
  idSearch,
  selectedRevID,
  queryKey,
  app,
  showOnlyCompletedFiles,
}: FilesQueryStateProps) => {
  const key = queryKey ? queryKey + (app?.id.toString() ?? '') : undefined;
  const [query, setQuery] = usePersistedQuery<FilesSortField, FilesSort>(
    key,
    FilesSort,
    queryKey
      ? undefined
      : getInitialQuery({
          initQuery: QueryHelpers.getInitValue<FilesSortField, FilesSort>(),
          app,
          globalSearch,
          idSearch,
          selectedRevID,
          showOnlyCompletedFiles,
        }),
    {
      onBeforeInit: (q: IFilesQuery) =>
        getInitialQuery({ initQuery: q, app, globalSearch, idSearch, selectedRevID, showOnlyCompletedFiles }),
    }
  );

  return [query, setQuery] as const;
};

const commonCurrentPageMutationsKeys = [
  createFileMutationKey,
  updateFileMutationKey,
  updateFileRevisionMutationKey,
  uploadFileMutationKey,
  uploadRetryFileMutationKey,
  uploadBatchFilesMutationKey,
];

const defaultFeatures = [FileFeature.LastRevision];
export const FilesList: FC<IFilesListProps> = (props) => {
  const {
    globalSearch,
    idSearch,
    selectedRevID,
    features = defaultFeatures,
    showOnlyCompletedFiles,
    queryKey,
    ...rest
  } = props;

  const queryClient = useQueryClient();

  const isAdmin = useSelector(authToolkit.selectors.isAdmin);
  const app = useSelector(appToolkit.selectors.app);

  const [query, setQuery] = useFilesQueryState({
    globalSearch,
    idSearch,
    selectedRevID,
    features,
    queryKey,
    app: app as App,
    showOnlyCompletedFiles,
  });
  const firstRender = useRef(true);

  const [collapseBy, setCollapseBy] = useState<Tag | undefined>(() => {
    const collapseByFilter = query.filterBy[FilesFilterField.CollapseTag] as TagCollapseFilter | undefined;
    return collapseByFilter?.by;
  });
  const [entitiesProcessing, setEntitiesProcessing] = useState<{ [id: number]: boolean }>({});
  const [enableQueryFiles, setEnableQueryFiles] = useState<boolean>(false);
  const [wasDeleteIconClicked, setWasDeleteIconClicked] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<FileResponse[]>([]);

  const setEntityProcessing = useCallback(
    (ids: FileID[], isProcessing: boolean) =>
      setEntitiesProcessing((prevState) => ({
        ...prevState,
        ...ids.reduce((acc: typeof entitiesProcessing, id) => ({ ...acc, [id.id]: isProcessing }), {}),
      })),
    []
  );

  const { files, total, errorMessage, mutateDeletedFile, isFetching, tags, refetch } = useFilesQuery(features, query, {
    enabled: enableQueryFiles,
  });

  const { checkedFiles, isChecking } = useFilesStatuses({
    files,
    filterByMissed: query.filterBy[FilesFilterField.IsMissed] as BooleanFilter,
    mutateDeletedFile,
  });

  const { mutate: deleteFile } = useFileDelete({
    onMutate: ({ fileID }) => {
      setWasDeleteIconClicked(true);
      setEntityProcessing([fileID], true);
    },
    onError: (err) => {
      snackbarService.genericFailure(err);
    },
    onSuccess: (_res, { fileID }) => {
      mutateDeletedFile([fileID]);
      queryClient.invalidateQueries({
        queryKey: fileGetKeys.concat(fileID),
      });
      snackbarService.success(`File has been deleted`);
    },
    onSettled: (_res, _err, { fileID }) => {
      setEntityProcessing([fileID], false);
    },
    removeQueriesKeys: filesQueryKeys,
    excludedRemoveQueriesKeys: tags,
  });
  const { mutate: deleteFiles } = useFileDeleteBatch({
    onMutate: (ids) => {
      setWasDeleteIconClicked(true);
      setEntityProcessing(ids, true);
    },
    onError: (err) => {
      snackbarService.genericFailure(err);
    },
    onSuccess: (_res, ids) => {
      mutateDeletedFile(ids);
      ids.forEach((id) => {
        queryClient.invalidateQueries({
          queryKey: fileGetKeys.concat(id),
        });
      });
      snackbarService.success(`${ids.length > 1 ? 'Files have' : 'File has'}  been deleted`);
    },
    onSettled: (_res, _err, ids) => {
      setEntityProcessing(ids, false);
    },
    removeQueriesKeys: filesQueryKeys,
    excludedRemoveQueriesKeys: tags,
  });

  useEffect(() => {
    if (firstRender.current) {
      return;
    }
    setQuery((q) => {
      const nextQuery = { ...q };
      const queryTagsFilter = nextQuery.filterBy[FilesFilterField.Tag] as TagsFilter | undefined;
      const tagsFilter = queryTagsFilter || new TagsFilter();

      if (app != null) {
        // remove app from tags
        tagsFilter.includes = tagsFilter.includes.filter((t: Tag) => t.key !== 'app');

        const appTag = new Tag({ key: 'app', value: `${app.id.id}` });
        tagsFilter.includes.push(appTag);
      }

      if (tagsFilter.includes.length || tagsFilter.excludes.length) {
        setEnableQueryFiles(true);
        nextQuery.filterBy[FilesFilterField.Tag] = tagsFilter;
      }

      return nextQuery;
    });
  }, [app, setQuery]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }

    setQuery((q) => {
      const nextQuery = { ...q };
      let anyFilter;
      if (globalSearch) {
        anyFilter = new TextFilter({ text: globalSearch });
      } else if (selectedRevID) {
        anyFilter = new TextFilter({ text: selectedRevID.file.toString() });
      }
      if (anyFilter) {
        setEnableQueryFiles(true);
        nextQuery.filterBy[FilesFilterField.Any] = anyFilter;
      }

      return nextQuery;
    });
  }, [globalSearch, firstRender, selectedRevID, setQuery]);

  const handleCollapseChange = useCallback(
    (tag?: Tag) => {
      setCollapseBy(tag);

      if (tag) {
        setQuery({
          ...query,
          filterBy: {
            ...query.filterBy,
            [FilesFilterField.CollapseTag]: new TagCollapseFilter({ by: tag.serialize() }),
          },
          iterator: QueryHelpers.getInitIterator(),
        });

        return;
      }

      const filterBy = Object.entries(query.filterBy).reduce((acc: QueryFilter, [key, entry]: [string, Filter]) => {
        if (key === FilesFilterField.CollapseTag) {
          return acc;
        }

        acc[key] = entry;
        return acc;
      }, {});

      setQuery({
        ...query,
        filterBy,
        iterator: QueryHelpers.getInitIterator(),
      });
    },
    [setQuery, setCollapseBy, query]
  );

  const handleCollapseApply = useCallback(
    (tag: Tag) => {
      if (!collapseBy) {
        return;
      }

      const nextQuery = query;

      // remove collapse tag from filterBy
      delete nextQuery.filterBy[FilesFilterField.CollapseTag];

      const tagFilter = nextQuery.filterBy[FilesFilterField.Tag] as TagsFilter | undefined;
      const nextTagsFilter = new TagsFilter();
      if (tagFilter) {
        nextTagsFilter.includes = tagFilter.includes.concat(tag);
        nextTagsFilter.excludes = tagFilter.excludes;
      } else {
        nextTagsFilter.includes = [tag];
        nextTagsFilter.excludes = [];
      }

      // prevent duplications
      nextTagsFilter.includes = _.uniqBy(nextTagsFilter.includes, (t: Tag) => getTagLabel(t));
      nextTagsFilter.excludes = _.uniqBy(nextTagsFilter.excludes, (t: Tag) => getTagLabel(t));

      nextQuery.filterBy[FilesFilterField.Tag] = nextTagsFilter;
      nextQuery.iterator = QueryHelpers.getInitIterator();

      setQuery(nextQuery);
      setCollapseBy(undefined);
    },
    [collapseBy, query, setQuery]
  );

  const canWrite = useCallback(
    (fr: FileResponse) => {
      const isSystem = fr.tags.some((t: Tag) => isSystemTag(t));

      return isAdmin || !isSystem;
    },
    [isAdmin]
  );

  const handleDeleteFile = (f: FileResponse) => {
    deleteFile({ fileID: f.file.id });
  };

  const handleDeleteBatch = () => {
    const label = `${selectedFiles.length} ${selectedFiles.length === 1 ? 'file' : 'files'}`;
    confirmDialog({
      title: `Delete  ${label}?`,
      text: `Are you sure you want to delete ${label}?`,
      type: ConfirmDialogType.Error,
      closeButton: { label: 'Cancel' },
      successButton: { label: 'Ok' },
      onSuccess: () => {
        deleteFiles(selectedFiles.map((selectedFile) => selectedFile.file.id));
      },
    });
  };

  const currentPageMutationsKeys = useMemo(
    () =>
      wasDeleteIconClicked
        ? commonCurrentPageMutationsKeys
        : [...commonCurrentPageMutationsKeys, removeFileMutationKey],
    [wasDeleteIconClicked]
  );

  return (
    <FilesListTable
      {...rest}
      initialQuery={query}
      queryKeys={tags}
      mutationsKeys={{
        currentPage: currentPageMutationsKeys,
        restPages: [
          removeFileMutationKey,
          createFileMutationKey,
          uploadRetryFileMutationKey,
          uploadBatchFilesMutationKey,
        ],
      }}
      toolbarActions={
        props.onSelect ? null : (
          <IconButton data-testid='delete-file-icon' onClick={handleDeleteBatch} size='large'>
            <DeleteIcon />
          </IconButton>
        )
      }
      options={{ ...(props.options ?? {}), withSelection: !props.onSelect }}
      onSelectionChange={setSelectedFiles}
      entitiesProcessing={entitiesProcessing}
      files={files}
      total={total}
      errorMessage={errorMessage}
      processing={isFetching || isChecking}
      checkedFiles={checkedFiles}
      canWrite={canWrite}
      onCollapseChange={handleCollapseChange}
      onCollapseApply={handleCollapseApply}
      onDeleteFile={handleDeleteFile}
      onQueryChange={setQuery}
      refetch={refetch}
      collapseBy={collapseBy}
      showOnlyCompletedFiles={showOnlyCompletedFiles}
      setEnableQueryEntities={setEnableQueryFiles}
    />
  );
};

import { FC, useContext } from 'react';

import { AppEntityControlBar } from '/shared/AppEntityControlBar';
import AppContext from '/component/Apps/App/AppContext';
import { AppLabels } from '/component/Apps/App/AppLabels/AppLabels';

export const AppControlBar: FC = () => {
  const { readonly, isAdmin, isValid, pending, backURL, noChanges, isCreating, onSave, onReset, onGoBack } =
    useContext(AppContext);

  return (
    <>
      <AppEntityControlBar
        componentIdLabels={<AppLabels />}
        label='Application'
        onSave={onSave}
        onReset={onReset}
        onGoBack={onGoBack}
        canSave={isAdmin && !readonly}
        canReset={isAdmin && !readonly}
        disabledSave={!isValid || pending}
        isVersioned={false}
        backURL={backURL}
        isCreating={isCreating}
        noChanges={noChanges}
      />
    </>
  );
};

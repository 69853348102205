import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { QueryKey } from '@tanstack/react-query';
import { Location } from 'history';
import { Card, CardHeader, CardContent } from '@mui/material';

import { currentModuleToolkit } from '/store';
import { Class } from '/common/models/enums';
import { relativeCreateURL } from '/constants';

import { AppControlBar } from './AppControlBar';
import { AppIcon } from './AppIcon';
import { AppSalts } from './AppSalts';
import { AppMarkets } from './AppMarkets';
import { AppPlatforms } from './AppPlatforms';
import { AppName } from './AppName';
import { AppProvider } from './AppContext';
import { useStyles } from './styles';

interface IAppContentProps {
  title?: string;
}

export const AppContent: FC<IAppContentProps> = ({ title }) => {
  const classes = useStyles();
  return (
    <Card>
      {title && <CardHeader title={title} />}
      <CardContent className={classes.appContent}>
        <div className={classes.appIcon}>
          <AppIcon />
        </div>
        <div>
          <AppName />
          <AppPlatforms />
        </div>
        <AppSalts />
        <AppMarkets />
      </CardContent>
    </Card>
  );
};

interface IAppProps extends RouteComponentProps<{ name: string }> {
  location: Location<{ currentPageQueryKey?: QueryKey }>;
}

export const App: FC<IAppProps> = (props) => {
  const appName = props?.match?.params?.name;
  const currentPageQueryKey = props.location?.state?.currentPageQueryKey;

  const dispatch = useDispatch();

  const title = appName === relativeCreateURL ? 'Create new application' : 'Edit application';

  useEffect(() => {
    if (appName !== relativeCreateURL) {
      dispatch(
        currentModuleToolkit.actions.setModule({
          moduleClass: Class.Apps,
        })
      );
    }
  }, [dispatch, appName]);

  return (
    <AppProvider appName={appName} currentPageQueryKey={currentPageQueryKey}>
      <AppControlBar />
      <AppContent title={title} />
    </AppProvider>
  );
};

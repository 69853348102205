import { FC } from 'react';

import { App as AppIDL } from '@playq/octopus2-apps';
import { AppID } from '@playq/octopus-common';

import { AppsSelection } from './AppsSelection';
import { AppsContent } from './AppsContent';

export interface IAppSelection {
  selectedAppID?: AppID;
  onSelectApp: (app: AppIDL) => void;
}

interface IAppsProps {
  isModal?: boolean;
  appSelection?: IAppSelection;
  onClose?: () => void;
}

export const Apps: FC<IAppsProps> = ({ isModal = false, appSelection, onClose }) => {
  return (
    <>
      {appSelection ? (
        <AppsContent
          selectedAppID={appSelection.selectedAppID}
          onSelectApp={appSelection.onSelectApp}
          isModal={isModal}
        />
      ) : (
        <AppsSelection isModal={isModal} onClose={onClose} />
      )}
    </>
  );
};

import { useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';
import { useEventCallback } from '@mui/material';

export type UseLeavePageDialogArgs<T> = {
  state: T;
  initialState: T;
  isLoading?: boolean;
  comparingFn?: (s: T | undefined, iS: T | undefined) => boolean;
};

export const useStateChange = <T,>(args?: boolean | UseLeavePageDialogArgs<T>) => {
  const noChanges = useMemo(() => {
    if (args === undefined) {
      return false;
    }

    if (typeof args === 'boolean') {
      return args;
    }

    const { state, initialState, isLoading, comparingFn } = args;

    if (isLoading === true) {
      return true;
    }

    if (comparingFn) {
      return comparingFn(state, initialState);
    }

    return isEqual(state, initialState);
  }, [args]);

  const handleWindowLoad = useEventCallback((e: BeforeUnloadEvent) => {
    if (noChanges === true) {
      return;
    }

    e.preventDefault();
    e.returnValue = '';
  });

  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowLoad);

    return () => {
      window.removeEventListener('beforeunload', handleWindowLoad);
    };
  }, [handleWindowLoad]);

  return noChanges;
};

import { string, object, mixed, lazy, Schema, Ref } from 'yup';

import { Market, MarketHelpers } from '@playq/services-shared';

import { relativeCreateURL } from '/constants';

export const saltPattern = new RegExp(/SIG-[A-Za-z0-9]\w{31}/);

export const getNameWithoutSpecialCharacters = (name: string) =>
  name
    .trim()
    // remove all non-alphanumeric symbols except spaces, -, and _
    .replaceAll(/[^a-z0-9\s-_]/gi, '')
    .toLowerCase();

export const getUnifiedName = (name: string) =>
  getNameWithoutSpecialCharacters(name)
    // replace repeating spaces, -, and _ with a single -
    .replaceAll(/[\s-_]{1,}/gi, '-')
    // remove spaces, -, and _ from the start and end of the string
    .replaceAll(/(^[\s-_]|[\s-_]$)/gi, '');

export const getNameValidationSchema = (existingNames: string[]): Schema<string> =>
  string()
    .trim()
    .required('Name is required!')
    .test(
      'name length',
      'Name should have at least 3 alphanumeric symbols!',
      (name: string) => getUnifiedName(name).length >= 3
    )
    .test(
      'validName',
      'Name should have only alphanumerical symbols',
      (name: string) => getNameWithoutSpecialCharacters(name).length === name.length
    )
    .test(
      'uniqueName',
      'Name should be unique',
      (name: string) => !existingNames.some((existingName) => getUnifiedName(existingName) === getUnifiedName(name))
    )
    // just for case someone will decide to create the app with name 'create'
    .test(
      'nameEqualsCreate',
      `Name ${relativeCreateURL} is forbidden`,
      (name: string) => name.toLowerCase() !== relativeCreateURL.toLowerCase()
    );

export const saltValidationSchema: Schema<string> = string()
  .length(36, 'Salt should be 36 symbols long')
  .matches(saltPattern, 'Invalid salt`s format!')
  .required('Salt is required!');

export const marketValidationSchema: Schema<string> = string().required('ID is required');

export const getValidationSchema = (
  existingNames: string[]
): Schema<{
  name: string;
  salts: { sandbox: string; live: string };
  markets: { [market: string]: unknown };
}> =>
  object().shape({
    name: getNameValidationSchema(existingNames),
    salts: object().shape({
      sandbox: saltValidationSchema,
      live: saltValidationSchema,
    }),
    markets: object().shape(
      MarketHelpers.all.reduce((map: { [market: string]: Ref | Schema<unknown> }, market: Market) => {
        map[market] = lazy((value: string) => {
          return value !== undefined ? marketValidationSchema : mixed().notRequired();
        });
        return map;
      }, {})
    ),
  });

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  textField: {
    pointerEvents: 'none',
    '& input': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
});

import {
  UserApiKeyData,
  AccessLevel,
  UserApiKeys,
  UserApiKeySerialized,
  UserApiKeyDataSerialized,
} from '@playq/octopus2-auth';

import { ITableColumn, ITableRowAction, ITableQuery } from '/shared/Table';
import { IGenericTableEntity } from '/common/models';

export interface IApiKeyData extends UserApiKeyData {
  key: string;
}

export type ApiKeyDataToCopy = UserApiKeySerialized | null;
export type ExistingApiKeyIdBeingProcessed = number | null;
export type KeyCreationDialogTogglingHandler = (shouldBeOpened: boolean) => () => void;
export type KeyCreationFormSubmissionHandler = (
  keyName: string,
  keyAccessLevel: AccessLevel,
  tableQuery: ITableQuery<string>
) => void;
export type TableEntriesSettingHandler = (data: IGenericTableEntity[]) => void;

export interface IApiKeyCreationMetadata {
  isKeyCreationBeingProcessed: boolean;
  newKeyName: string;
  newKeyAccessLevel: AccessLevel;
  tableQueryToPerformAfterKeyCreation: ITableQuery<string> | null;
}

export interface IApiKeysTableContentMetadata
  extends IIndependentApiKeysTableContentMetadata,
    IDependentApiKeysTableContentMetadata {}

export interface IIndependentApiKeysTableContentMetadata {
  columns: ITableColumn<UserApiKeyDataSerialized, ApiKeysQueryFields>[];
  rowKeyResolver: (entry: UserApiKeyDataSerialized) => number;
}

export interface IDependentApiKeysTableContentMetadata {
  data: UserApiKeyDataSerialized[];
  rowActions: ITableRowAction<UserApiKeyDataSerialized>[];
}

export enum ApiKeysQueryFields {
  ID = 'id',
  Name = 'name',
  CreatedAt = 'created',
  LastUsedAt = 'lastUsed',
}

export interface IApiKeys extends UserApiKeys {
  keys: IApiKeyData[];
}

export interface IKeyCreationProcessingConfig {
  isCreationBeingProcessed: boolean;
  keyName?: string;
  keyAccessLevel?: AccessLevel;
  tableQuery?: ITableQuery<string>;
}

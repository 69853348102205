import { styled, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Spacing } from '@mui/system';

import { BooleanFilter } from '@playq/octopus-common';

import { getOppositeColor } from '/helpers';
import { PreviewSizes } from '/constants/file';
import { IThemeDependentStyledComponentProps } from '/common/models';
import { DEFAULT_SELECTED_SEARCH_ICON_COLOR, INITIAL_SEARCH_ICON_COLOR } from '/shared/Table';

export const useUploadDialogStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '500px',
  },
  content: {
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100%',
    width: '100%',
  },
  actionButton: {
    marginTop: 10,
  },
  title: {
    color: theme.palette.secondary.main,
  },
}));

export const usePickButtonStyles = makeStyles({
  container: {
    position: 'relative',
  },
  button: {
    float: 'right',
  },
});

export const usePickerStyles = makeStyles((theme: Theme) => ({
  appBarTitle: {
    flex: '1',
  },
  tableContainer: {
    marginTop: 100,
  },
  appBarIcon: {
    color: getOppositeColor(theme.palette.primary.main),
  },
}));

export const SELECTED_MISSED_SEARCH_ICON_COLOR = '#d32f2f';

export const useListStyles = makeStyles((theme: Theme) => {
  const themeSpacing: Spacing = theme.spacing.bind(theme);

  return {
    idCell: {
      width: 100,
    },
    breakAllCell: {
      maxWidth: 150,
      wordBreak: 'break-all',
      minWidth: 100,
    },
    breakWordCell: {
      maxWidth: 200,
      wordBreak: 'break-word',
      minWidth: 100,
    },

    previewDivider: {
      margin: `${themeSpacing()}px 0`,
      width: 130,
    },

    preview: {
      width: `${PreviewSizes.small}px`,
      height: `${PreviewSizes.small}px`,
      position: 'relative',
    },

    toolbar: {
      display: 'flex',
    },
    toolbarCollapse: {
      alignSelf: 'flex-end',
      marginBottom: `${parseInt(themeSpacing(1)) + 5}px`,
      marginRight: themeSpacing(2),
    },
    toolbarFilter: {
      flex: 1,
    },
    uploadingWrapper: {
      paddingLeft: 0,
    },
  };
});

export const useListContentStyles = makeStyles({
  cardContent: {
    width: '100%',
    overflow: 'auto',
  },
});

export const PickerLabelStyled = styled('div')<IThemeDependentStyledComponentProps>`
  position: relative;
  background-color: ${({ $themePalette }: IThemeDependentStyledComponentProps) => $themePalette?.warning.main};
  color: ${({ $themePalette }: IThemeDependentStyledComponentProps) =>
    getOppositeColor($themePalette?.warning.main as string)};

  &:hover {
    background-color: ${({ $themePalette }: IThemeDependentStyledComponentProps) => $themePalette?.warning.dark};
  }

  cursor: pointer;
  max-width: 150px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  padding: 3px 20px 3px 5px;
`;

export const usePickerLabelStyles = makeStyles((theme: Theme) => ({
  icon: {
    position: 'absolute',
    top: '25%',
    right: theme.spacing(1),
    fontSize: 14,
    verticalAlign: 'middle',
  },
}));

export const getFilterByMissedIconColor = (filterByMissed?: BooleanFilter) => {
  if (!filterByMissed) {
    return INITIAL_SEARCH_ICON_COLOR;
  }
  if (filterByMissed.value) {
    return SELECTED_MISSED_SEARCH_ICON_COLOR;
  }
  if (!filterByMissed.value) {
    return DEFAULT_SELECTED_SEARCH_ICON_COLOR;
  }
};

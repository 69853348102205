import { FC, useContext } from 'react';
import { withStyles, WithStyles } from '@mui/styles';

import { LabelThemed, LabelThemedColor } from '/shared/LabelThemed';
import AppContext from '/component/Apps/App/AppContext';
import { snackbarService } from '/common/snackbarService';

import { styles } from './styles';

const AppLabelsContent: FC<WithStyles<typeof styles>> = ({ classes }) => {
  const { app, onCopyLabel } = useContext(AppContext);

  const handleClick = (value: string | number) => () => {
    if (typeof value === 'string') {
      snackbarService.info(`${app.name} FingerprintID copied to clipboard!`);
    } else {
      snackbarService.info(`${app.name} ID copied to clipboard!`);
    }
    onCopyLabel(value);
  };

  const shouldShowAppID = app.id !== undefined && app.id.id > 0;
  const shouldHideFingerprintID = !app.id?.id;

  return (
    <div>
      {shouldShowAppID && (
        <LabelThemed
          data-testid='app-id-label'
          color={LabelThemedColor.Grey}
          clickable={true}
          className={classes?.appLabel || ''}
          onClick={handleClick(app.id.id)}
        >
          AppID: {app.id.id}
        </LabelThemed>
      )}
      {!shouldHideFingerprintID && (
        <LabelThemed
          color={LabelThemedColor.Grey}
          clickable={true}
          className={classes?.appLabel || ''}
          onClick={handleClick(app.fingerprintID.app)}
        >
          FingerprintID: {app.fingerprintID.app}
        </LabelThemed>
      )}
    </div>
  );
};

export const AppLabels = withStyles(styles)(AppLabelsContent);

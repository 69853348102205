import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { from, Observable, of } from 'rxjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import { map, combineAll } from 'rxjs/operators';
import * as _ from 'lodash';
import { useSelector } from 'react-redux';
import { Typography, useEventCallback } from '@mui/material';

import { FileFeature, FilesFilterField, UploadFileEntryRequest } from '@playq/octopus2-files';
import { Tag, OptionsFilter, TagsFilter } from '@playq/octopus-common';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import {
  UploadSingleResponse,
  UploadBatchResponse,
  UploadNewFile,
  FileRevisionID,
  FileID,
} from '@playq/octopus2-files';

import { IFileUpload } from '/common/models';
import { useUniqueCollection } from '/hooks';
import { snackbarService } from '/common/snackbarService';
import { isTagsEqual, uploadBinToAws, createAdapter, IEntityDefinition } from '/helpers';
import { useScriptsMetadata } from '/common/scripts';
import { FILE_UPLOAD_HOOK_NAME, FILES_BATCH_UPLOAD_HOOK_NAME, useFileGetDownloadUrl, useFilesQuery } from '/api';
import { appToolkit } from '/store';
import { fileContentExistsError } from '/constants';
import { confirmDialog, ConfirmDialogType } from '/common/ConfirmDialog';
import { parsePath, Target } from '/shared/FilePreview/helpers';
import { defaultFileType } from '/constants';
import { IMutationScriptedOptionsVariables } from '/api/hooks/scriptsService/types';
import { IInvokeScriptsHook } from '/common/scripts/types';

import {
  ErrorRecord,
  FileIDRecord,
  IFilesUploadState,
  ProgressRecord,
  UseFilesUploadArgs,
  HandleUploadBinToAwsArgs,
} from './types';
import { fileUploadHelpers } from './helpers';

const queryFilesFeatures = [FileFeature.LastRevision];

const def: IEntityDefinition<IFileUpload> = { selectID: (f: IFileUpload) => f?.id };
const adapter = createAdapter<IFileUpload>(def);

const emptyMutationFunc = () => ({
  mutate: () => {},
});

export const useFilesUpload = ({
  definition,
  initEntities,
  initTags,
  fileRevisionID: fileRevisionIDSerialized,
  retryFileID: retryFileIDSerialized,
  checkExisting,
  removeQueries,
}: UseFilesUploadArgs): IFilesUploadState => {
  const firstRun = useRef(true);

  const isUploadRetry = fileRevisionIDSerialized !== undefined;
  const fileRevisionID = useMemo(
    () => (!fileRevisionIDSerialized ? undefined : new FileRevisionID(fileRevisionIDSerialized)),
    [fileRevisionIDSerialized]
  );
  const { data: uploadRetryData } = useFileGetDownloadUrl(fileRevisionID);
  const expectedMime = useMemo(
    () => (!uploadRetryData?.url ? undefined : parsePath(uploadRetryData.url, Target.Type)),
    [uploadRetryData]
  );
  const retryFileID = useMemo(
    () => (!retryFileIDSerialized ? undefined : new FileID(retryFileIDSerialized)),
    [retryFileIDSerialized]
  );

  const [state, setState] = useState(() => {
    const nEntities = fileUploadHelpers.createManyUploads(initEntities || [], initTags);
    return adapter.addAll(nEntities, adapter.getInitialState());
  });
  const [uploadFailedErrors, setUploadFailedErrors] = useState<ErrorRecord>({});

  const uploadingEntity = useRef<IFileUpload | undefined>();

  const { invokeScriptsHook } = useScriptsMetadata();

  const { data: existingFilesData } = useFilesQuery(
    queryFilesFeatures,
    {
      filterBy: {
        [FilesFilterField.Name]: new OptionsFilter({ options: initEntities?.map((e) => e.name) ?? [] }),
        [FilesFilterField.Tag]: new TagsFilter({
          excludes: [new Tag({ key: '$type', value: 'ua' }).serialize()],
          includes: [],
        }),
      },
      sortBy: [],
      iterator: new OffsetLimit({ offset: 0, limit: 1000 }),
    },
    {
      enabled: checkExisting && state.ids.length > 0,
    }
  );

  useEffect(() => {
    setState((s) => {
      const entities = adapter.selectAll(s);
      const existingFiles = checkExisting ? (existingFilesData?.entities ?? []) : [];

      const nEntities = entities.map((fu: IFileUpload) => {
        const foundInstances = existingFiles.filter((e) => e.file.name === fu.origin.name);
        return {
          ...fu,
          foundInstances,
          selectedInstance: foundInstances[0],
          tags: foundInstances[0]?.tags ?? initTags,
        };
      });
      return adapter.upsertMany(nEntities, s);
    });
  }, [checkExisting, existingFilesData?.entities, initTags]);

  const appID = useSelector(appToolkit.selectors.appID);

  useEffect(() => {
    if (!isUploadRetry) {
      return;
    }
    const { entities } = state;
    const entitiesIDs = Object.keys(entities);
    if (entitiesIDs.length <= 1) {
      return;
    }
    const lastID = entitiesIDs[entitiesIDs.length - 1];
    const lastEntity = entities[lastID];
    setState((s) => ({
      ...s,
      entities: { [lastID]: lastEntity },
    }));
  }, [isUploadRetry, state]);

  const validateBulk = useCallback(
    (fus: IFileUpload[], reservedErrors: ErrorRecord) => {
      if (isUploadRetry) {
        return;
      }
      const validate = (fu: IFileUpload): Observable<ErrorRecord> => {
        const id = def.selectID(fu);
        return from(definition.validate(fu)).pipe(
          map((err: undefined | string) => ({ [id]: err || reservedErrors[id] }))
        );
      };

      const batchValidation: Observable<ErrorRecord>[] = fus.map((fu: IFileUpload) => validate(fu));
      from(batchValidation)
        .pipe(
          combineAll(),
          map((res: ErrorRecord[]) => res.reduce((acc: ErrorRecord, next: ErrorRecord) => ({ ...acc, ...next }), {}))
        )
        .subscribe((records: ErrorRecord) => setErrors((s) => ({ ...s, ...records })));
    },
    // we should not compare definition. It should be as constant
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isUploadRetry]
  );

  const handleValidateBulk = useEventCallback((fu: IFileUpload[]) => {
    validateBulk(fu, uploadFailedErrors);
  });

  useEffect(
    function updateStateWitnInitTags() {
      if (firstRun.current || !initTags) {
        return;
      }
      setState((s) => {
        const entities = adapter.selectAll(s);

        const nEntities = entities.map((fu: IFileUpload) => {
          initTags.forEach((iT: Tag) => {
            const idx = fu.tags.findIndex((t: Tag) => t.key === iT.key);
            if (idx > -1) {
              fu.tags[idx] = iT;
            } else {
              fu.tags.push(iT);
            }
          });

          return fu;
        });

        return adapter.upsertMany(nEntities, s);
      });
    },
    [initTags]
  );

  useEffect(
    function updateStateWithNewEntities() {
      if (firstRun.current) {
        firstRun.current = false;
        return;
      }

      const nEntities = fileUploadHelpers.createManyUploads(initEntities || []);
      handleValidateBulk(nEntities);
      setState((s) => adapter.addMany(nEntities, s));
    },
    [initEntities, handleValidateBulk]
  );

  const processing = useUniqueCollection();
  const { add, addMany, delete: deleteEntity, deleteMany } = processing;

  const [progress, setProgress] = useState<ProgressRecord>({});
  const [selected, setSelected] = useState<string[]>([]);
  const [errors, setErrors] = useState<ErrorRecord>({});
  const [fileIDs, setFileIDs] = useState<FileIDRecord>({});

  const validateSingle = useCallback(
    async (fu: IFileUpload) => {
      const id = def.selectID(fu);

      const err = await definition.validate(fu);
      if (err) {
        setErrors((s) => ({
          ...s,
          [def.selectID(fu)]: err,
        }));

        return;
      }

      setErrors((s) => {
        return _.omit(s, id);
      });
    },
    [definition]
  );

  const getEntitiesToProcess = useCallback((): IFileUpload[] => {
    return selected.length ? adapter.selectMany(selected, state) : adapter.selectAll(state);
  }, [state, selected]);

  const isBulkUploadValid = useCallback(() => {
    return getEntitiesToProcess().every((fu: IFileUpload) => definition.isValid(fu));
  }, [getEntitiesToProcess, definition]);

  const onRemoveSuccess = useEventCallback((shouldConfirm?: boolean) => () => {
    if (!uploadingEntity.current) {
      return;
    }
    const id = def.selectID(uploadingEntity.current);
    const updateErrors = (s: ErrorRecord) => _.omit(s, id);
    setErrors(updateErrors);
    setUploadFailedErrors(updateErrors);
    setSelected((s) => s.filter((selectedId) => selectedId !== id));
    setState((s) => adapter.removeOne(uploadingEntity.current as IFileUpload, s));
    if (shouldConfirm) {
      removeQueries?.();
      snackbarService.success('File has been deleted');
    }
  });

  const { mutate: deleteFile } = (definition.delete ?? emptyMutationFunc)({
    onSuccess: onRemoveSuccess(true),
    onError: () => snackbarService.error('Could not remove the file.'),
  });

  const remove = useEventCallback((fu: IFileUpload) => {
    uploadingEntity.current = fu;
    const fileID = fu.fileID;
    if ((!fileID && !retryFileID) || !definition.delete || (!appID && definition.isAsset)) {
      onRemoveSuccess()();
      return;
    }

    confirmDialog({
      title: `DELETE ${fu.name}? `,
      type: ConfirmDialogType.Error,
      text: (
        <>
          <Typography>You can still continue with uploading the file.</Typography>
          <Typography>Click CANCEL to continue.</Typography>
        </>
      ),
      onSuccess: () => deleteFile({ appID, fileID: (fileID ?? retryFileID) as FileID }),
      closeButton: { label: 'Cancel' },
    });
  });

  const handleProgress = useEventCallback((id: string) => (p: number) => {
    setProgress((s) => ({
      ...s,
      [id]: p,
    }));
  });

  const handleUploadBinToAws = ({ url, headers, fileID, entity }: HandleUploadBinToAwsArgs) => {
    const entityID = def.selectID(entity);
    uploadBinToAws(url, headers, entity.origin, { onProgress: handleProgress(entity.id) })
      .then(() => {
        snackbarService.success(`File: ${entity.name} has been uploaded`);
        setState((s) => adapter.removeOne(entity, s));
        setSelected((s) => s.filter((selectedId) => selectedId !== entityID));
      })
      .catch((err: Error) => {
        snackbarService.error(`File: ${entity.name} hasn't been uploaded`);
        const updateErrors = (s: ErrorRecord) => ({
          ...s,
          [entityID]: s[entityID] || fileUploadHelpers.getErrorMessage(err),
        });
        setErrors(updateErrors);
        setUploadFailedErrors(updateErrors);
        return of();
      })
      .finally(() => {
        if (fileID !== undefined) {
          setFileIDs((fIDs) => ({ ...fIDs, [entityID]: fileID }));
        }
        setProgress((s) => _.omit(s, entity.id));
        deleteEntity(entity.id);
      });
  };

  const onUploadEntrySuccess = useEventCallback((fu: IFileUpload) => (r: UploadSingleResponse) => {
    const fileID = r?.file?.id;
    if (fileID !== undefined) {
      const entityID = def.selectID(fu);
      setFileIDs((fIDs) => ({ ...fIDs, [entityID]: fileID }));
    }
    removeQueries?.();
    const { url, headers } = r.request;
    handleUploadBinToAws({ url, headers, entity: fu, fileID });
  });

  const handleUploadSingleSuccess = useEventCallback((r: UploadSingleResponse) => {
    if (!uploadingEntity.current) {
      return;
    }
    onUploadEntrySuccess(uploadingEntity.current)(r);
  });

  const handleUploadSingleError = useEventCallback((err: GenericFailure | Error) => {
    const fileUpload = uploadingEntity.current;
    if (!fileUpload) {
      return;
    }
    const id = def.selectID(fileUpload);
    if (err.message !== fileContentExistsError) {
      const updateErrors = (s: ErrorRecord) => ({ ...s, [id]: fileUploadHelpers.getErrorMessage(err as Error) });
      setErrors(updateErrors);
      setUploadFailedErrors(updateErrors);
      snackbarService.error(`File: ${fileUpload.origin.name} has't been uploaded`);
    }
    if (err.message === fileContentExistsError) {
      setState((s) => adapter.removeOne(fileUpload, s));
    }
  });

  const handleUploadRetrySuccess = useEventCallback((res: UploadSingleResponse) => {
    handleUploadSingleSuccess(res);
    fileUploadHelpers.updateRevisionUpdatedAt(res.revision, definition.updateRevision);
  });

  const { mutate: uploadRetry } = definition.uploadRetry({
    onSuccess: handleUploadRetrySuccess,
    onError: handleUploadSingleError,
  });

  const { mutate: uploadFileEntity } = definition.uploadFileEntity(invokeScriptsHook, {
    onSuccess: handleUploadSingleSuccess,
    onError: handleUploadSingleError,
  });

  const validateUploadSingle = useEventCallback(async (fu: IFileUpload, retry?: boolean) => {
    const id = def.selectID(fu);

    if (!retry) {
      const error = await definition.validate(fu);
      if (error !== undefined) {
        setErrors((s) => ({ ...s, [id]: error }));
        return false;
      }
    }

    if (retry && expectedMime !== undefined && expectedMime !== defaultFileType) {
      const uploadingType = fu.origin.type;
      if (uploadingType !== expectedMime) {
        setErrors((s) => ({
          ...s,
          [def.selectID(fu)]: 'File extension should be the same as it was before.',
        }));
        return false;
      }
    }

    return true;
  });

  const uploadSingle = useEventCallback(async (fu: IFileUpload, retry?: boolean) => {
    if (definition.isAsset && !appID) {
      return;
    }
    uploadingEntity.current = fu;
    if (retry && retryFileID) {
      fu.fileID = retryFileID;
    }
    const id = def.selectID(fu);

    const isValid = await validateUploadSingle(fu, retry);
    if (!isValid) {
      return;
    }

    const entryRequest = await fileUploadHelpers.createEntryRequest({ ...fu, selectedInstance: undefined });
    if (!entryRequest) {
      return;
    }

    add(id); // const nSet = clone(previousSet); nSet.add(id); setState(nSet);

    if (!retry) {
      uploadFileEntity({
        serviceMethodArgs: definition.isAsset ? [appID, entryRequest] : [entryRequest],
        scriptsResolverArgs: [FILE_UPLOAD_HOOK_NAME, entryRequest],
      });
      return;
    }

    const entry = entryRequest.entry;
    if (entry instanceof UploadNewFile) {
      return;
    }
    const entryFileID = entry.fileID;
    if (entryFileID === undefined) {
      return;
    }
    const lastRevision = await fileUploadHelpers.getLastRevisionFromFileID(entryFileID, definition.getFile);
    if (lastRevision?.id !== undefined) {
      uploadRetry({ appID, id: lastRevision.id, mime: fu.origin.type, sha256: entry.sha256 });
    }
  });

  const handleUploadBatchError = useEventCallback((err: GenericFailure | Error) => {
    snackbarService.error(`Batch Upload Failure. Msg: ${fileUploadHelpers.getErrorMessage(err)}`);
    const ids = getEntitiesToProcess().map((e: IFileUpload) => def.selectID(e));
    deleteMany(ids);
    const updateErrors = (s: ErrorRecord) => ({
      ...s,
      ...ids.reduce((acc: ErrorRecord, curr) => {
        acc[curr] = fileUploadHelpers.getErrorMessage(err);
        return acc;
      }, {}),
    });
    setErrors(updateErrors);
    setUploadFailedErrors(updateErrors);
  });

  const processBatchUpload = useEventCallback(
    async (
      res: UploadBatchResponse,
      { serviceMethodArgs }: IMutationScriptedOptionsVariables<unknown[], IInvokeScriptsHook>
    ) => {
      removeQueries?.();

      const entities = getEntitiesToProcess();

      const upload = serviceMethodArgs.find((arg) => Array.isArray(arg)) as UploadFileEntryRequest[] | undefined;

      if (!upload) {
        return;
      }

      upload.forEach((_e, idx: number) => {
        const { url, headers } = res.batch[idx].request;
        const entity = entities[idx];
        const fileID = res.batch[idx].file?.id;
        handleUploadBinToAws({ url, fileID, entity, headers });
      });
    }
  );

  const { mutate: uploadBatch } = definition.uploadBatch(invokeScriptsHook, {
    onSuccess: processBatchUpload,
    onError: handleUploadBatchError,
  });

  const uploadMany = useEventCallback(async () => {
    const allEntities = getEntitiesToProcess();

    const { entities, ids } = allEntities.reduce(
      (result: { entities: IFileUpload[]; ids: string[] }, entity) => {
        const id = def.selectID(entity);
        if (errors[id] === 'Unknown Error' || (fileIDs[id] === undefined && errors[id] === undefined)) {
          result.entities.push(entity);
          result.ids.push(id);
        }
        return result;
      },
      {
        entities: [],
        ids: [],
      }
    );

    const isSomeInvalid = entities.some((fu: IFileUpload) => !definition.isValid(fu));
    if (isSomeInvalid) {
      return;
    }

    const entryRequests = await fileUploadHelpers.createManyEntryRequests(
      checkExisting ? entities : entities.map((e) => ({ ...e, selectedInstance: undefined }))
    );

    if (!entryRequests) {
      return;
    }

    addMany(ids);

    if (definition.isAsset && !appID) {
      return;
    }

    uploadBatch({
      serviceMethodArgs: definition.isAsset ? [appID, entryRequests] : [entryRequests],
      scriptsResolverArgs: [FILES_BATCH_UPLOAD_HOOK_NAME, entryRequests],
    });
  });

  const updateSingle = useCallback(
    (fu: IFileUpload) => {
      validateSingle(fu);
      setState((s) => adapter.updateOne(fu, s));
    },
    [validateSingle]
  );

  const updateMany = useCallback(
    (fus: IFileUpload[]) => {
      handleValidateBulk(fus);
      setState((s) => adapter.updateMany(fus, s));
    },
    [handleValidateBulk]
  );

  const putNew = useCallback(
    (files: File[]) => setState((s) => adapter.upsertMany(fileUploadHelpers.createManyUploads(files), s)),
    []
  );

  const reset = useCallback(
    (fu: IFileUpload) => {
      const r = fileUploadHelpers.reset(fu);
      validateSingle(r);
      setState((s) => adapter.updateOne(r, s));
    },
    [validateSingle]
  );

  const toggleSelect = useCallback((fu: IFileUpload) => {
    const id = def.selectID(fu);
    setSelected((s) => (s.includes(id) ? s.filter((selectedId) => selectedId !== id) : [...s, id]));
  }, []);

  const masterSelect = useCallback(() => {
    const count = adapter.selectCount(state);
    if (count === selected.length) {
      setSelected([]);
    } else {
      adapter.selectIDs(state);
      setSelected(adapter.selectIDs(state));
    }
  }, [state, selected]);

  const addTags = useCallback(
    (tags: Tag[]) => {
      const entities = getEntitiesToProcess();
      updateMany(
        entities.map((e) => {
          const uniqueTagsMap = new Map<string, Tag>();

          [...e.tags, ...tags].forEach((tag) => {
            uniqueTagsMap.set(`${tag.key}: ${tag.value ?? ''}`, tag);
          });

          return {
            ...e,
            tags: Array.from(uniqueTagsMap.values()),
          };
        })
      );
    },
    [getEntitiesToProcess, updateMany]
  );

  const applyTags = useCallback(
    (tags: Tag[]) => {
      const entities = getEntitiesToProcess();
      updateMany(
        entities.map((e) => ({
          ...e,
          tags,
        }))
      );
    },
    [getEntitiesToProcess, updateMany]
  );

  const removeTags = useCallback(
    (tags: Tag[]) => {
      const entities = getEntitiesToProcess();

      updateMany(
        entities.map((e) => ({
          ...e,
          tags: e.tags.filter((fileTag) => !tags.some((newTag) => isTagsEqual(fileTag, newTag))),
        }))
      );
    },
    [getEntitiesToProcess, updateMany]
  );

  const applyComment = useCallback(
    (comment: string) => {
      const entities = getEntitiesToProcess();
      updateMany(
        entities.map((entity) => ({
          ...entity,
          comment,
        }))
      );
    },
    [getEntitiesToProcess, updateMany]
  );

  const removeComments = useCallback(() => {
    const entities = getEntitiesToProcess();

    updateMany(
      entities.map((e) => ({
        ...e,
        comment: 'No comment provided',
      }))
    );
  }, [getEntitiesToProcess, updateMany]);

  return {
    state,
    adapter,
    processing,
    errors,
    progress,
    selected,
    fileIDs,
    isBulkUploadValid,
    validateBulk: handleValidateBulk,

    putNew,
    updateSingle,
    updateMany,
    toggleSelect,
    masterSelect,
    remove,
    uploadSingle,
    uploadMany,
    reset,
    addTags,
    applyTags,
    removeTags,
    applyComment,
    removeComments,
  };
};

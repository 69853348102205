/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, MouseEvent, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, CardHeader, Link, Stack, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Delete as DeleteIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
} from '@mui/icons-material';

import { GenericFailure, GenericFailureCode, MarketHelpers } from '@playq/services-shared';
import {
  App,
  AppsFilterField,
  AppsFilterFieldHelpers,
  AppsSort,
  AppsSortField,
  AppsSortFieldHelpers,
  PlatformHelpers,
} from '@playq/octopus2-apps';
import { AppID, OptionsFilter, TextFilter } from '@playq/octopus-common';
import { AccessLevel, PermModule } from '@playq/octopus2-auth';

import { usePersistedQuery } from '/hooks';
import {
  appsQueryTags,
  createAppMutationKey,
  removeAppMutationKey,
  updateAppMutationKey,
  useAppDelete,
  useAppsQuery,
} from '/api';
import { AppsView, appToolkit, authToolkit, uiToolkit } from '/store';
import { IQuery, PersistedQueryKeys, SyntheticEventWithMetaKey } from '/common/models';
import { confirmDialog } from '/common/ConfirmDialog';
import { snackbarService } from '/common/snackbarService';
import { CollectionTable, ICollectionTableColumn, ICollectionTableQueryChange, ITableRowAction } from '/shared/Table';
import { navigateToURL } from '/helpers';
import { CreateButton } from '/shared/CreateButton';
import { relativeCreateURL } from '/constants';

import { AppIcon } from './AppIcon';
import { useAppPreviewDialog } from './useAppPreviewDialog';
import { AppsTiles } from './AppsTiles';
import { PlatformIcon } from './AppsTiles/PlatformIcon';
import { MarketIcon } from './AppsTiles/MarketIcon';
import { useStyles } from './styles';

const AppsTable = CollectionTable.ofType<App, AppsSortField, AppsFilterField, AppsSort>();

const getURLForAppRouteName = (appRouteName: string): string => `/apps/manage/${appRouteName}`;

export interface IAppsContentProps {
  isModal: boolean;
  selectedAppID?: AppID;
  onSelectApp: (app: App) => void;
}

export const AppsContent: FC<IAppsContentProps> = (props) => {
  const { isModal, selectedAppID = new AppID(), onSelectApp } = props;
  const classes = useStyles();
  const selectedApp = useSelector(appToolkit.selectors.app);
  const companyPerms = useSelector(authToolkit.selectors.companyPerms);
  const perms = useSelector(appToolkit.selectors.perms);
  const isAdmin = useSelector(authToolkit.selectors.isAdmin);
  const view = useSelector(uiToolkit.selectors.appsView);
  const { canRead, canWrite, canWriteLive } = useSelector(
    appToolkit.selectors.moduleAccess(PermModule.ApplicationsManagement)
  );
  const dispatch = useDispatch();
  const updateView = useCallback((v: AppsView) => dispatch(uiToolkit.actions.setAppsView(v)), [dispatch]);

  const [entitiesProcessing, setEntitiesProcessing] = useState<{ [id: string]: boolean }>({});
  const [enableQueryApps, setEnableQueryApps] = useState<boolean>(false);

  const setEntityProcessing = useCallback(
    (id: string, isProcessing: boolean) => setEntitiesProcessing((prevState) => ({ ...prevState, [id]: isProcessing })),
    []
  );

  const [appPreviewDialogNode, appPreviewDialogApi] = useAppPreviewDialog();

  const [query, setQuery] = usePersistedQuery(
    isModal ? PersistedQueryKeys.AppsModal : PersistedQueryKeys.Apps,
    AppsSort
  );

  const {
    apps,
    total,
    errorMessage,
    isLoading,
    tags: keys,
    refetch,
    mutateDeletedApp,
  } = useAppsQuery(query.iterator, query.sortBy, query.filterBy, { enabled: enableQueryApps });

  const { mutate: deleteApp } = useAppDelete({
    onMutate: (app: App) => {
      setEntityProcessing(app.id.serialize(), true);
      setEnableQueryApps(false);

      if (selectedAppID.id === app.id.id) {
        dispatch(appToolkit.actions.resetApp());
      }

      snackbarService.success(`Application ${app.id.id} has been deleted`);
    },
    onSettled: (_res, _err, app) => {
      setEntityProcessing(app.id.serialize(), false);
    },
    onError: (err, app: App) => {
      if (err instanceof GenericFailure && err.code === GenericFailureCode.EntityNotFound) {
        snackbarService.warning(`Application ${app.name} already been deleted`);
        return;
      }
      snackbarService.genericFailure(err);
    },
    onSuccess: (_res, app) => {
      mutateDeletedApp(app.id);
    },
    removeQueriesKeys: appsQueryTags,
    excludedRemoveQueriesKeys: keys,
  });

  const canEditApp = useCallback(
    (app: App) => {
      const companyAccess = companyPerms.access[PermModule.ApplicationsManagement];
      return (
        !!isAdmin ||
        (selectedApp && app.fingerprintID.serialize() === perms?.scope?.serialize()
          ? canWrite || canWriteLive
          : companyAccess === AccessLevel.Write || companyAccess === AccessLevel.WriteLive)
      );
    },
    [canWrite, canWriteLive, companyPerms.access, isAdmin, perms?.scope, selectedApp]
  );

  const handleDelete = useCallback(
    (app: App) => {
      if (!canWrite) {
        return;
      }
      confirmDialog({
        title: `DELETE ${app.name}?`,
        text: `Are you sure you want to delete the ${app.name}?`,
        closeButton: { label: 'NO' },
        successButton: { label: 'YES' },
        onSuccess: () => deleteApp(app),
      });
    },
    [canWrite, deleteApp]
  );

  const isAppProcessing = useCallback((app: App) => !!entitiesProcessing[app.id.serialize()], [entitiesProcessing]);

  const getAppURL = (app: App) => getURLForAppRouteName(app.routeName);

  const handleAppRedirect = useCallback(
    (appRouteName: string, event?: SyntheticEventWithMetaKey) => {
      const url = getURLForAppRouteName(appRouteName);
      navigateToURL({ url, event, state: { currentPageQueryKey: keys } });
    },
    [keys]
  );

  const handleCreate = (event: SyntheticEventWithMetaKey) => handleAppRedirect(relativeCreateURL, event);

  const handleGoToApp = useCallback(
    (e: SyntheticEventWithMetaKey, app: App) => {
      if (isAppProcessing(app)) {
        return;
      }
      if (canRead && !canEditApp(app)) {
        appPreviewDialogApi.open(app);
        return;
      }
      if (canEditApp(app)) {
        handleAppRedirect(app.routeName, e);
      }
    },
    [appPreviewDialogApi, canEditApp, canRead, handleAppRedirect, isAppProcessing]
  );

  const handleSelectApp = useCallback(
    (e: SyntheticEvent, app: App) => {
      e.stopPropagation();
      onSelectApp(app);
    },
    [onSelectApp]
  );

  const appNameSelector = useCallback(
    (app: App) => {
      const handleSelect = (event: SyntheticEvent) => {
        handleSelectApp(event, app);
      };

      const nameLimit = 65;
      const showTooltip = app.name.length >= nameLimit;

      const link = (
        <Link
          component='button'
          onClick={handleSelect}
          underline={'hover'}
          color={'default'}
          data-testid='link-app-name'
        >
          <span className={classes.nameIconWrapper}>
            <div className={classes.appIconWrapper}>
              <AppIcon app={app} />
            </div>
            <span className={classes.appName}>{app.name}</span>
          </span>
        </Link>
      );

      return showTooltip ? (
        <Tooltip placement='top' title={app.name}>
          {link}
        </Tooltip>
      ) : (
        link
      );
    },
    [classes, handleSelectApp]
  );

  const columns: ICollectionTableColumn<App, AppsSortField, AppsFilterField>[] = useMemo(
    () => [
      {
        queryKey: 'ID',
        label: 'ID',
        classes: {
          headerCell: classes.idHeaderCell,
        },
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        render: (app: App) => (app.id ? app.id.id : ''),
      },
      {
        queryKey: 'Name',
        filterType: TextFilter.ClassName,
        label: 'Name',
        classes: {
          cell: classes.nameCell,
        },
        render: (app: App) => {
          return (
            <>
              {appNameSelector(app)}
              {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
              {app.id && app.id.id === selectedAppID.id && <CheckCircleIcon className={classes.selectedLabel} />}
            </>
          );
        },
      },
      {
        label: 'Platforms',
        filterType: OptionsFilter.ClassName,
        filterData: PlatformHelpers.all,
        queryKey: 'Platform',
        render: (app: App) => (
          <Stack direction='row' spacing={1} flexWrap='wrap'>
            {app.platforms?.map((platform) => (
              <Stack direction='row' key={platform} spacing={1 / 2}>
                <PlatformIcon platform={platform} />
                {platform}
              </Stack>
            ))}
          </Stack>
        ),
      },
      {
        label: 'Markets',
        filterType: OptionsFilter.ClassName,
        filterData: MarketHelpers.all,
        queryKey: 'Market',
        render: (app: App) => (
          <Stack direction='row' spacing={1} flexWrap='wrap'>
            {app.markets?.map(({ market }) => (
              <Stack direction='row' key={market} spacing={1 / 2}>
                <MarketIcon market={market} />
                {market}
              </Stack>
            ))}
          </Stack>
        ),
      },
    ],
    [appNameSelector, classes, selectedAppID.id]
  );

  const actions: ITableRowAction<App>[] = useMemo(
    () => [
      {
        label: 'Delete',
        icon: DeleteIcon,
        tooltip: (app: App) => `Delete ${app.name}`,
        tooltipPlacement: 'top',
        pending: (app: App) => isAppProcessing(app),
        hidden: (app) => !canEditApp(app),
        onClick: (_event: SyntheticEvent, app: App) => handleDelete(app),
      },
    ],
    [canEditApp, handleDelete, isAppProcessing]
  );

  const createRowKey = (item: App): number => {
    return item.id.id;
  };

  const handleQueryChange = (qc: ICollectionTableQueryChange<IQuery<AppsSortField, AppsSort>>) => {
    setQuery(qc.query);
  };

  const toggleViewChange = (_event: MouseEvent<HTMLElement>, value: AppsView) => {
    updateView(value);
  };

  return (
    <Card>
      <CardHeader
        title='Applications'
        action={
          !isModal && (
            <Stack direction='row' spacing={2}>
              <ToggleButtonGroup value={view} onChange={toggleViewChange} exclusive={true}>
                <ToggleButton value={AppsView.List} aria-label={AppsView.List}>
                  <ViewListIcon />
                </ToggleButton>
                <ToggleButton value={AppsView.Tile} aria-label={AppsView.Tile}>
                  <ViewModuleIcon />
                </ToggleButton>
              </ToggleButtonGroup>
              {(canWrite || canWriteLive) && (
                <CreateButton aria-label='Add' data-testid='icon-add' onClick={handleCreate} />
              )}
            </Stack>
          )
        }
      />
      <CardContent data-testid='card-content'>
        {isModal || view === AppsView.List ? (
          <AppsTable
            sortHelper={AppsSortFieldHelpers}
            filterHelper={AppsFilterFieldHelpers}
            sortClass={AppsSort}
            columns={columns}
            actions={!isModal ? actions : undefined}
            data={apps}
            total={total}
            error={errorMessage}
            initialQuery={query}
            onQueryChange={handleQueryChange}
            getRowKey={createRowKey}
            refetch={refetch}
            processing={isLoading}
            classes={classes}
            contentProcessing={false}
            placeholder={{
              text: 'There is no apps yet. Press Plus button to create a new one.',
            }}
            onRowClick={!isModal ? handleGoToApp : handleSelectApp}
            getEntityURL={getAppURL}
            queryKeys={keys}
            mutationsKeys={{
              currentPage: [createAppMutationKey, updateAppMutationKey],
              restPages: [createAppMutationKey, removeAppMutationKey],
            }}
            setEnableQueryEntities={setEnableQueryApps}
          />
        ) : (
          <AppsTiles
            apps={apps}
            selectedApp={selectedApp}
            query={query}
            onQueryChange={handleQueryChange}
            onDelete={handleDelete}
            canEditApp={canEditApp}
            total={total}
            loading={isLoading}
          />
        )}
      </CardContent>
      {appPreviewDialogNode}
    </Card>
  );
};

import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    appSalts: {
      marginTop: theme.spacing(2),
      display: 'grid',
      rowGap: theme.spacing(3),
    },
    appSaltsLabel: {
      display: 'flex',
      alignItems: 'center',
    },
    appSaltsButton: {
      padding: '5px',
    },
    appSaltsInfoIcon: {
      margin: '0 2px',
    },
  });

import { FC, useContext } from 'react';
import { List } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';

import { Platform, PlatformHelpers } from '@playq/octopus2-apps';

import AppContext from '/component/Apps/App/AppContext';
import { BlockThemed } from '/shared/BlockThemed';

import AppPlatform from './AppPlatform';
import { styles } from './styles';

const AppPlatformsComponent: FC<WithStyles<typeof styles>> = ({ classes }) => {
  const { form, readonly, onUpdateValue } = useContext(AppContext);
  const allPlatforms = PlatformHelpers.all;

  const selected = (platform: Platform) => form.platforms.includes(platform);

  const togglePlatform = (platform: Platform) => () => {
    if (readonly) {
      return;
    }

    const pls = [...form.platforms];
    const idx = pls.findIndex((p: string) => p === platform);
    if (idx > -1) {
      pls.splice(idx, 1);
    } else {
      pls.push(platform);
    }

    onUpdateValue('platforms', pls);
  };

  const platformItems = allPlatforms.map((platform: Platform) => {
    return (
      <AppPlatform
        key={platform}
        classes={classes}
        platform={platform}
        selected={selected(platform)}
        readonly={readonly}
        togglePlatform={togglePlatform(platform)}
      />
    );
  });

  return (
    <BlockThemed label='Platforms' classes={{ block: classes.appPlatforms }}>
      <List>{platformItems}</List>
    </BlockThemed>
  );
};

export const AppPlatforms = withStyles(styles)(AppPlatformsComponent);

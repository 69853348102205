import { FC, useEffect, useRef } from 'react';
import { Card, CardContent, CardHeader, Fab } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import { SelectDialog } from '/shared/SelectDialog';
import { ITableChangeQuery, ITableQuery } from '/shared/Table';
import { useGenericTablePersistedQuery, useTableQueryChange } from '/hooks';
import { IGenericTableEntity, PersistedQueryKeys } from '/common/models';

import { ApiKeysQueryFields } from '../types';
import { ApiKeysTable } from '../ApiKeysTable';
import { ApiKeyForm } from '../ApiKeyForm';
import { ApiKeyCreatedCopyingPanel } from '../ApiKeyCreatedCopyingPanel';

import { IApiKeysManagerContentProps } from './types';

export const ApiKeysManagerContent: FC<IApiKeysManagerContentProps> = (props) => {
  const {
    keysData,
    isKeyCreationDialogOpened,
    isKeyCreationBeingProcessed,
    isTableProcessing,
    keyDataToCopy,
    defaultKeyAccessLevel,
    tableContentMetadata: { columns, rowActions, rowKeyResolver, data: tableEntries },
    keyCreationDialogTogglingHandler,
    keyCreationFormSubmissionHandler,
    keyCreationDialogFadingOutHandler,
    tableEntriesSettingHandler,
    isTableProcessingSettingHandler,
  }: IApiKeysManagerContentProps = props;

  const [query, setQuery] = useGenericTablePersistedQuery({ key: PersistedQueryKeys.ApiKeys });
  const [tableQuery, setTableQuery] = useTableQueryChange({
    tableData: keysData as unknown as IGenericTableEntity[],
    tableEntriesSettingHandler,
    tableQuery: query,
    isTableProcessingSettingHandler,
  });

  const didCancel = useRef<boolean>(false);

  useEffect(() => {
    return () => {
      didCancel.current = true;
    };
  }, []);

  useEffect(() => {
    setTableQuery(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, keysData]);

  let keyCreationDialogTitle: string;
  let keyCreationDialogBody: JSX.Element;

  const handleQueryChange = (qc: ITableChangeQuery) => {
    setQuery(qc.query);
  };

  if (!keyDataToCopy) {
    keyCreationDialogTitle = 'New Key';
    keyCreationDialogBody = (
      <ApiKeyForm
        isKeyCreationBeingProcessed={isKeyCreationBeingProcessed}
        defaultKeyAccessLevel={defaultKeyAccessLevel}
        tableQueryToPerformAfterKeyCreation={tableQuery}
        keyCreationFormSubmissionHandler={keyCreationFormSubmissionHandler}
      />
    );
  } else {
    keyCreationDialogTitle = `Key ${keyDataToCopy?.name}`;
    keyCreationDialogBody = (
      <ApiKeyCreatedCopyingPanel
        keyDataToCopy={keyDataToCopy}
        keyCreationDialogTogglingHandler={keyCreationDialogTogglingHandler}
      />
    );
  }

  return (
    <Card>
      <CardHeader
        title='API Keys'
        action={
          <Fab color='primary' aria-label='Add' onClick={keyCreationDialogTogglingHandler(true)}>
            <AddIcon />
          </Fab>
        }
      />

      <CardContent>
        <ApiKeysTable
          columns={columns}
          data={tableEntries}
          actions={rowActions}
          getRowKey={rowKeyResolver}
          pending={true}
          initialQuery={tableQuery as ITableQuery<ApiKeysQueryFields>}
          onQueryChange={handleQueryChange}
          placeholder={{
            text: `There are no any API keys yet. Create new ones.`,
          }}
          options={{ withPagination: true }}
          processing={isTableProcessing}
        />
      </CardContent>

      <SelectDialog
        open={isKeyCreationDialogOpened}
        onClose={keyCreationDialogTogglingHandler(false)}
        title={keyCreationDialogTitle}
        content={keyCreationDialogBody}
        maxWidth='sm'
        TransitionProps={{
          onExited: keyCreationDialogFadingOutHandler,
        }}
      />
    </Card>
  );
};

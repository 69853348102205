import { randomBytes } from 'crypto';

import { App, AppsSort, AppsSortField } from '@playq/octopus2-apps';

import { QueryHelpers, resetModel } from '/helpers';

import { IAppState } from './types';

export const maxQuery = QueryHelpers.getMaxValue<AppsSortField, AppsSort>();

const createApp = (): App => {
  const newApp = new App();
  resetModel(newApp);
  newApp.salts.sandbox = `SIG-${randomBytes(16).toString('hex')}`;
  newApp.salts.live = `SIG-${randomBytes(16).toString('hex')}`;

  return newApp;
};
export const emptyApp = createApp();

export const emptyAppState: IAppState = {
  form: {
    name: '',
    salts: {
      sandbox: '',
      live: '',
    },
    platforms: [],
    markets: {},
  },
  readonly: false,
  isValid: false,
  appImage: undefined,
  selectedFile: undefined,
  marketImages: {},
};

export const backURL = '/apps';

import { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import * as octopusApps from '@playq/octopus2-apps';
import * as octopusCommon from '@playq/octopus-common';

import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { ServicesContext, Services2 } from '/api';
import { confirmDialog, ConfirmDialogType } from '/common/ConfirmDialog';

import { ScriptContext } from './definitions';

export function useScriptContext(utilities?: {
  log?: (message: string) => void;
  popup?: (message: string) => Promise<void>;
  prompt?: (message: string) => Promise<boolean>;
}): ScriptContext {
  const services: Services2 = useContext(ServicesContext).services2;
  const appID: octopusCommon.AppID | undefined = useSelector(appToolkit.selectors.appID);

  const log = useCallback(
    (message: string) => {
      if (utilities?.log) {
        utilities.log(message);
      } else {
        // eslint-disable-next-line no-console
        console.log(message);
      }
    },
    [utilities]
  );

  const popup = useCallback(
    (message: string, options?: { title?: string; okButtonText?: string; type?: ConfirmDialogType }) =>
      new Promise<void>((resolve) => {
        const messageAsHtml = (
          <div>
            {message.split('\n').map((fragment, idx) => (
              <p key={fragment + String(idx)}>{fragment}</p>
            ))}
          </div>
        );

        confirmDialog({
          type: options?.type,
          title: options?.title || 'Alert',
          text: messageAsHtml,
          successButton: { label: options?.okButtonText || 'Ok' },
          onSuccess: () => resolve(),
        });
      }),
    []
  );

  const prompt = useCallback(
    (message: string, options?: { title?: string; yesButtonText?: string; noButtonText?: string }) =>
      new Promise<boolean>((resolve) => {
        confirmDialog({
          title: options?.title || 'Prompt',
          text: message,
          closeButton: { label: options?.noButtonText || 'No' },
          successButton: { label: options?.yesButtonText || 'Yes' },
          onSuccess: () => resolve(true),
          onClose: () => resolve(false),
        });
      }),
    []
  );

  const context = useMemo<ScriptContext>(() => {
    return {
      appID: appID as octopusCommon.AppID,
      log,
      popup,
      prompt,
      toast: (message: string, type: 'success' | 'error' | 'info') => {
        switch (type) {
          case 'info':
            snackbarService.info(message);
            break;
          case 'success':
            snackbarService.success(message);
            break;
          case 'error':
            snackbarService.error(message);
            break;
          default:
            snackbarService.default(message);
        }
      },
      packages: {
        common: octopusCommon,
        apps: octopusApps,
      },
      services: {
        config: services.appConfigService,
        apps: services.appsService,
        spaces: services.spacesService,
        files: services.filesService,
        inventory: services.appsInventoryService,
        packages: services.appsPackagesService,
        experiments: services.appExperimentsService,
        traits: services.appTraitsService,
        events: services.appEventsMapperService,
        segmentation: services.appSegmentsService,
        leaderboards: services.lboardsService,
        teams: services.teamsService,
        gameEvents: services.appGameEventsService,
        synapseProblems: services.synapseProblemsService,
        flows: services.flowsService,
        assets: services.appAssetsService,
        notifications: services.notificationsTimelineService,
        cloudFunctions: services.cloudFunctionsService,
      },
    };
  }, [appID, log, popup, prompt, services]);

  return context;
}

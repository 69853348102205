import { useState, useCallback } from 'react';

import { App } from '@playq/octopus2-apps';

import { PreviewEntity } from '/shared/PreviewEntity';

import { AppProvider } from './App/AppContext';
import { AppContent } from './App/App';

export const useAppPreviewDialog = () => {
  const [open, setOpen] = useState(false);
  const [app, setApp] = useState<App | undefined>();

  const handleOpen = useCallback((nextApp: typeof app) => {
    setApp(nextApp);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const node = (
    <PreviewEntity
      title='App'
      handleClose={handleClose}
      open={open}
      content={
        app && (
          <AppProvider appName={app.routeName} readonly={true}>
            <AppContent />
          </AppProvider>
        )
      }
    />
  );

  return [node, { open: handleOpen }] as const;
};

import { FC } from 'react';
import { Android, Apple, Language } from '@mui/icons-material';

import { Platform as AppPlatform } from '@playq/octopus2-apps';

import { SecondaryIcon } from './styles';

export const PlatformIcon: FC<{ platform: AppPlatform }> = ({ platform }) => {
  let Icon;
  switch (platform) {
    case AppPlatform.Android: {
      Icon = Android;
      break;
    }
    case AppPlatform.iOS: {
      Icon = Apple;
      break;
    }
    case AppPlatform.Web: {
      Icon = Language;
      break;
    }
  }

  return <SecondaryIcon component={<Icon />} />;
};

import { useCallback, useRef, useState } from 'react';

import { FilePicker, IFilePickerProps, ISelectedFile } from './FilePicker';

type Props = Omit<IFilePickerProps, 'onSelect' | 'onClose'> & { id?: string };

export const useFilePicker = (disableClose = false) => {
  const [props, setProps] = useState<Props>({});
  const [show, setShow] = useState(false);
  const resolveRef = useRef<((value?: ISelectedFile) => void) | undefined>(undefined);

  const handleSelect = useCallback((selectedFile: ISelectedFile) => {
    resolveRef.current?.(selectedFile);
    setShow(false);
  }, []);

  const handleClose = useCallback(() => {
    resolveRef.current?.();
    setShow(false);
  }, []);

  const pickFile = (newProps?: Props) => {
    setProps(newProps || {});
    setShow(true);
    return new Promise<ISelectedFile | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  const node = show && (
    <FilePicker
      {...props}
      open={show}
      disablePersist={true}
      onSelect={handleSelect}
      onClose={disableClose ? undefined : handleClose}
    />
  );

  return [node, pickFile] as const;
};

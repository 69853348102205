import { FC, useCallback, useState, useEffect, useMemo, useRef, ChangeEvent, memo } from 'react';
import { Button, FormControlLabel, Switch, Stack, TextField, ButtonGroup, Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { App } from '@playq/octopus2-apps';
import { Tag } from '@playq/octopus-common';

import { filesQueryKeys } from '/api';

import { useFilesUpload } from './useFileUpload';
import { BulkApply, UploadList, defaultFileUploadDef } from './shared';
import { Toolbar, BulkUploadButton } from './styles';

export interface IFileUploadProps {
  initFiles: File[];
  app?: App;
  fileRevisionID?: string;
  retryFileID?: string;
  onUploadFinish: () => void;
}

export const FilesUpload: FC<IFileUploadProps> = memo(
  ({ initFiles, app, fileRevisionID, retryFileID, onUploadFinish }) => {
    const [checkExisting, setExisting] = useState(false);
    const [comment, setComment] = useState('');
    const initTags = useMemo(() => {
      const result: Tag[] = [];
      if (app) {
        result.push(new Tag({ value: app.id.id.toString(), key: 'app' }));
      }

      return result;
    }, [app]);

    const queryClient = useQueryClient();

    const removeQueries = useCallback(() => {
      queryClient.removeQueries(filesQueryKeys);
    }, [queryClient]);

    const {
      state,
      adapter,
      errors,
      progress,
      processing,
      selected,
      fileIDs,
      isBulkUploadValid,

      remove,
      toggleSelect,
      removeTags,
      addTags,
      applyTags,
      applyComment,
      removeComments,
      uploadSingle,
      uploadMany,
      updateSingle,
      reset,
      masterSelect,
    } = useFilesUpload({
      definition: defaultFileUploadDef,
      initEntities: initFiles,
      initTags,
      fileRevisionID,
      retryFileID,
      checkExisting,
      removeQueries,
    });
    const firstRun = useRef(true);

    useEffect(() => {
      if (firstRun.current) {
        firstRun.current = false;
        return;
      }

      if (adapter.selectCount(state) === 0) {
        onUploadFinish();
      }
    }, [adapter, onUploadFinish, state]);

    const handleCommentChange = (e: ChangeEvent<HTMLInputElement>) => setComment(e.target.value);
    const handleApplyComment = () => applyComment(comment);
    const handleRemoveComments = () => {
      setComment('');
      removeComments();
    };

    const disabled = Boolean(processing.size);

    const shouldShowMasterSelect = !retryFileID && state.ids.length > 1;

    return (
      <>
        {!fileRevisionID && (
          <Toolbar>
            <Stack direction='row' gap={1}>
              <TextField
                value={comment}
                onChange={handleCommentChange}
                placeholder='Enter comment'
                fullWidth={true}
                size='small'
              />
              <ButtonGroup color='primary' aria-label='outlined primary button group'>
                <Tooltip placement='top' data-testid='replace-comment' title='Apply comment' enterDelay={1000}>
                  <Button
                    onClick={handleApplyComment}
                    disabled={disabled || comment.length < 3}
                    variant='contained'
                    color='primary'
                  >
                    Apply
                  </Button>
                </Tooltip>
                <Tooltip placement='top' data-testid='remove-comments' title='Remove comments' enterDelay={1000}>
                  <Button onClick={handleRemoveComments} disabled={disabled} variant='contained' color='secondary'>
                    Remove
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Stack>
            <BulkApply
              tags={initTags}
              disabled={disabled}
              onAddTags={addTags}
              onApplyTags={applyTags}
              onRemoveTags={removeTags}
            />
            <BulkUploadButton
              onClick={uploadMany}
              disabled={!isBulkUploadValid() || processing.size > 0}
              data-testid='icon-batch'
              variant='contained'
            >
              Upload
            </BulkUploadButton>
            <FormControlLabel
              data-testid='check-files-button'
              control={
                <Switch
                  checked={checkExisting}
                  onChange={() => setExisting((prev) => !prev)}
                  disabled={disabled || !isBulkUploadValid() || processing.size > 0}
                  color='primary'
                />
              }
              label='Check existing'
              sx={{
                m: 0,
                ml: 1,
              }}
            />
          </Toolbar>
        )}

        <UploadList
          state={state}
          errors={errors}
          progress={progress}
          adapter={adapter}
          selected={selected}
          processing={processing}
          checkExisting={checkExisting}
          fileIDs={fileIDs}
          shouldShowMasterSelect={shouldShowMasterSelect}
          fileRevisionID={fileRevisionID}
          onMasterSelect={masterSelect}
          onRemove={remove}
          onReset={reset}
          onUploadSingle={uploadSingle}
          onUpdateSingle={updateSingle}
          onToggleSelect={toggleSelect}
        />
      </>
    );
  }
);

import { array, object, string } from 'yup';
import _ from 'lodash';

import {
  FileResponse,
  FileRevision,
  DownloadSingleResponse,
  CheckFileRequest,
  FileState,
  CheckFileResponse,
  FileRevisionID,
} from '@playq/octopus2-files';

import { parseFileName, navigateToURL } from '/helpers';
import { IFileUpload, SyntheticEventWithMetaKey } from '/common/models';
import { ConfirmDialogType, confirmDialog } from '/common/ConfirmDialog';

import { IFile, IFileEdit } from './types';

const DOWNLOAD_THROTTLE_TIMEOUT = 1500;

const fieldRequiredSchema = (field: string) => string().trim().required(`${field} is required!`);

const fileEditSchema = object().shape({
  name: fieldRequiredSchema('Name'),
  comment: fieldRequiredSchema('Comment').min(3, 'min 3 symbols'),
  tags: array(),
});

const fileEditRevisionSchema = object().shape({
  name: fieldRequiredSchema('Name'),
});

const getFile = (file: FileResponse): IFile => {
  return {
    origin: file,
    author: file.file.author,
    name: file.file.name,
    updatedAt: file.file.updatedAt,
    id: file.file.id,
    meta: file.file.meta,
    createdAt: file.file.createdAt,
    tags: file.tags,
  };
};

const getFileEdit = (file: IFile, rev: IFileUpload | FileRevision): IFileEdit => {
  const [name, ext] = parseFileName(file.name);
  return {
    origin: file,
    ext,
    size: rev instanceof FileRevision ? rev.size : rev.origin.size,
    media: rev instanceof FileRevision ? rev.media : undefined,

    name,
    tags: file.tags,
    comment: rev.comment,
    sha256: rev instanceof FileRevision ? rev.sha256 : undefined,
  };
};

export const fileEditHelpers = {
  getFile,
  getFileEdit,
  fileEditSchema,
  fileEditRevisionSchema,
};

export const handleSuccessDownloadFile = (r: DownloadSingleResponse | DownloadSingleResponse[]) => {
  const responses = Array.isArray(r) ? r : [r];

  const download = (index: number) => {
    if (index >= responses.length) {
      return;
    }
    const element = document.createElement('a');
    element.setAttribute('download', `relative`);
    element.setAttribute('href', responses[index].url);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    setTimeout(function () {
      download(index + 1);
    }, DOWNLOAD_THROTTLE_TIMEOUT);
  };

  download(0);
};

export const filesURL = '/files';
export const configURL = '/configuration';

export const goToFiles = (event?: SyntheticEventWithMetaKey, id?: string) => {
  const fileUrl = id ? `${filesURL}/${id}` : filesURL;
  navigateToURL({ url: fileUrl, event });
};

export function lastRevision(f: FileResponse): FileRevision {
  return _.maxBy(f.revisions, (r: FileRevision) => r.version) as FileRevision;
}

const timeToWaitFileProcessing = 5 * 60 * 1000;

export const checkIfFileRevisionMissedOrProcessing = (
  revision: FileRevision,
  status: FileState
): { missed: boolean; processing: boolean } => {
  const currentTime = Date.now();

  const missed = status === FileState.NotFound;
  const shouldWait = currentTime - revision.updatedAt.getTime() <= timeToWaitFileProcessing;

  return { missed, processing: shouldWait };
};

export const checkIfFileStatusShouldBeUpdated = (
  f: FileResponse,
  checkedFiles: CheckFileResponse[]
): { missed: boolean; processing: boolean } => {
  const lR = lastRevision(f);
  const status = checkedFiles.find(({ request }) => (request as FileRevisionID).id === lR.id.id)?.state;
  return status ? checkIfFileRevisionMissedOrProcessing(lR, status) : { missed: false, processing: false };
};

export const showCustomConfirmRemoveDialog = ({
  entityName,
  confirmRemove,
}: {
  entityName: string;
  confirmRemove: () => void;
}): void => {
  return confirmDialog({
    title: `Delete ${entityName}?`,
    text: `Are you sure you want to delete the ${entityName}?`,
    type: ConfirmDialogType.Error,
    closeButton: { label: 'NO' },
    successButton: { label: 'YES' },
    onSuccess: confirmRemove,
  });
};

export const getCheckFilesRequest = (files?: FileResponse[]): CheckFileRequest[] =>
  (files ?? []).map((fileResponse) => lastRevision(fileResponse).id);

import { FC, useState, useEffect, memo } from 'react';
import { Button, ButtonGroup, Tooltip, Box, ButtonProps } from '@mui/material';

import { Tag } from '@playq/octopus-common';

import { Tags } from '/shared/Tags';

import { Wrapper, useBulkApplyStyles } from './styles';
import { IBulkApplyProps } from './types';

export const BulkApply: FC<IBulkApplyProps> = memo((props) => {
  const { tags: initTags, disabled, onAddTags, onApplyTags, onRemoveTags } = props;
  const classes = useBulkApplyStyles();

  const [tags, setTags] = useState<Tag[]>(() => initTags || []);
  useEffect(() => initTags && setTags(initTags), [initTags]);

  const handleChangeTags = (t: Tag[]) => {
    setTags(t);
  };

  const handleAddTags = () => {
    onAddTags?.(tags);
  };

  const handleApplyTags = () => {
    onApplyTags(tags);
  };

  const handleRemoveTags = () => {
    onRemoveTags(tags);
  };

  const actionButtons: (ButtonProps & { dataTestId: string })[] = [
    {
      onClick: handleAddTags,
      color: 'warning',
      name: 'Add',
      title: 'Add new tags',
      dataTestId: 'add-new-tags',
      hidden: !onAddTags,
    },
    {
      onClick: handleApplyTags,
      color: 'primary',
      name: onAddTags ? 'Replace' : 'Apply',
      title: 'Apply and Replace all',
      dataTestId: 'replace-all-tags',
    },
    {
      onClick: handleRemoveTags,
      color: 'secondary',
      name: 'Remove',
      title: 'Remove from all',
      dataTestId: 'remove-from-all',
    },
  ];

  return (
    <Wrapper>
      <Box flex='1'>
        <Tags
          tags={tags}
          onChange={handleChangeTags}
          readonly={disabled}
          className={classes.tagsContainer}
          label={null}
        />
      </Box>
      <ButtonGroup color='primary' aria-label='outlined primary button group'>
        {actionButtons.map(({ hidden, name, title, color, dataTestId, onClick }) => (
          <Tooltip key={name} placement='top' data-testid={dataTestId} title={title} enterDelay={1000}>
            <Button
              className={classes.action}
              disabled={disabled}
              hidden={hidden}
              variant='contained'
              color={color}
              onClick={onClick}
            >
              {name}
            </Button>
          </Tooltip>
        ))}
      </ButtonGroup>
    </Wrapper>
  );
});

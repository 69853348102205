import { Card, styled } from '@mui/material';
import { green } from '@mui/material/colors';

export const OnlineIndicator = styled('div')<{ online?: boolean }>`
  background-color: ${(props) => (props.online ? green[400] : props.theme.palette.grey[400])};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  bottom: 2px;
  left: 14px;
`;
export const CardStyled = styled(Card)<{ $stream: boolean }>`
  width: ${(props) => (props.$stream ? '60%' : '100%')};
  position: ${(props) => (props.$stream ? 'static' : 'relative')};
`;

import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardContent, CardHeader, Fab, IconButton, Link } from '@mui/material';
import { ArrowBack as ChevronLeftIcon, Folder as FolderIcon } from '@mui/icons-material';
import { Prompt, Router, useHistory, useParams } from 'react-router-dom';

import { FileRevisionID, FileID } from '@playq/octopus2-files';

import { FilesPickDialog } from '/shared/FilesPickDialog/FilesPickDialog';
import { Dropzone } from '/shared/Dropzone';
import { appToolkit } from '/store';
import { IFilesRouteProps } from '/common/models';

import { goToFiles, filesURL } from '../File/helpers';

import { getStateFromRoute } from './shared';
import { FilesUpload } from './FilesUpload';

export const FilesUploadPage: FC<IFilesRouteProps> = (props) => {
  const firstRender = useRef(true);
  const app = useSelector(appToolkit.selectors.app);
  const [files, setFiles] = useState<File[]>(() => getStateFromRoute(props.location).files);
  const [shouldClose, setShouldClose] = useState(false);

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    setFiles(getStateFromRoute(props.location).files);
  }, [props.location]);

  const fileRevisionIDSerialized = getStateFromRoute(props.location).fileRevisionID;
  const fileRevisionID = useMemo(
    () => (!fileRevisionIDSerialized ? undefined : new FileRevisionID(fileRevisionIDSerialized)),
    [fileRevisionIDSerialized]
  );

  const retryFileIDSerialized = getStateFromRoute(props.location).retryFileID;
  const retryFileID = useMemo(
    () => (!retryFileIDSerialized ? undefined : new FileID(retryFileIDSerialized)),
    [retryFileIDSerialized]
  );

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpen = useCallback(() => setDialogOpen(true), []);

  const handleClose = useCallback(() => setDialogOpen(false), []);

  const handleFilesRead = useCallback((fs: File[]) => {
    setFiles(fs);
    setDialogOpen(false);
  }, []);

  const handleFinishUpload = () => {
    setShouldClose(true);
  };

  useEffect(
    function goToFilesIfShouldClose() {
      if (shouldClose) {
        history.goBack();
      }
    },
    [shouldClose, history]
  );
  const initFiles = useMemo(() => (!fileRevisionIDSerialized ? files : [files[0]]), [fileRevisionIDSerialized, files]);
  return (
    <Router history={history}>
      <Prompt
        when={!shouldClose}
        message={(location) => {
          if (location.pathname === '/files/upload') {
            return false;
          }
          return 'Are you sure? Unsaved changes will be lost.';
        }}
      />
      <Dropzone onFilesRead={handleFilesRead}>
        <Card>
          <CardHeader
            title={
              <>
                <Link href={filesURL}>
                  <IconButton onClick={(event) => goToFiles(event, id)} data-testid='go-back-button' size='large'>
                    <ChevronLeftIcon />
                  </IconButton>
                </Link>
                File Upload
              </>
            }
            action={
              <Fab color='primary' data-testid='icon-upload' aria-label='Upload' onClick={handleOpen}>
                <FolderIcon />
              </Fab>
            }
          />
          <CardContent>
            <FilesUpload
              initFiles={initFiles}
              fileRevisionID={fileRevisionIDSerialized}
              retryFileID={retryFileIDSerialized}
              onUploadFinish={handleFinishUpload}
              app={app}
            />
          </CardContent>
        </Card>

        <FilesPickDialog
          open={dialogOpen}
          fileRevisionID={fileRevisionID}
          retryFileID={retryFileID}
          onFilesRead={handleFilesRead}
          onClose={handleClose}
        />
      </Dropzone>
    </Router>
  );
};
